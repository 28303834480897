/*------------------*
# sub-menu
*------------------*/

.sub-menu {
  position: absolute;
  padding: 0;
  min-width: 240px;
  z-index: 4;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0 0;
  transform-style: preserve-3d;
  @include transform(rotateX(-75deg));
}
.main-menu {
  li {
    &:hover > {
      .sub-menu {
        opacity: 1;
        visibility: visible;
        transition: transform 0.3s, opacity 0.3s;
        @include transform(rotateX(0deg));
      }
    }
  }
}
.sub-menu {
  li {
    padding: 0 20px;
    border-bottom: 1px solid $border-color2;
    margin: 0;
    a {
      font-weight: fontweight(regular);
      text-align: left;
      text-transform: capitalize;
      padding: 0;
      color: #565656;
      font-size: 14px;
      line-height: 40px;

      i {
        margin-left: 10px;
      }
    }
    &:hover > a {
      padding-left: 5px;
    }
  }
}

.sub-menu {
  .sub-menu {
    opacity: 0;
    visibility: hidden;
    left: 100%;
    top: 0;
    transform-origin: 0 0;
    transform-style: preserve-3d;
    @include transform(rotateX(-75deg));
  }
}

/*------------------*
# sub-menu END
*------------------*/
