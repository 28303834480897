/*------------------*
# About Us Page
*------------------*/

.about-content .title {
  font-weight: fontweight(semi-bold);
  color: $color-dark;
  text-transform: capitalize;
  font-size: 30px;
  @include max-screen(767) {
    font-size: 25px;
  }
  max-width: 900px;
  margin: 0 auto 20px;
  line-height: 1.4;
}

.about-info .title {
  font-size: 34px;

  font-weight: fontweight(semi-bold);
  color: $color-dark;
}

.single-service {
  .title {
    font-size: 22px;
    font-weight: fontweight(semi-bold);
    color: $color-dark;
  }
}

.service-section {
  .single-blog {
    img {
      width: 100%;
    }
  }
}
/* *******************
   about us page End
 **********************/
