/*******************************
 sticky header
 *******************************/

#sticky.is-isticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  @include transition(0.3s);
  animation: ease-in-out 0.5s fadeInDown;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}
// theme1
.theme1 {
  &.is-isticky {
    background: $color-white;
    &:hover {
      background: $color-white;
    }
  }
}


#sticky.custom-sticky.is-isticky {
  padding-top: 4px;
  padding-bottom: 4px;
  @include max-screen(991) {
    position: static !important;
  }
}

/*******************************
 sticky header End
 *******************************/
