/*******************
   map page start
 ******************/

.map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

/* ***************
   map page End
 ******************/
