/******************************** 
  offcanvas toggler btn
 ********************************/
.mobile-menu-toggle {
  svg {
    width: 50px;
    position: relative;
    @include min-screen(576) {
      top: -8px;
    }
    path {
      transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
        stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
      fill: none;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-width: 30px;
      &#top,
      &#bottom {
        stroke-dasharray: 240px 950px;
      }
      &#middle {
        stroke-dasharray: 240px 240px;
      }
    }
  }

  & .close {
    svg {
      path {
        &#top,
        &#bottom {
          stroke-dasharray: -650px;
          stroke-dashoffset: -650px;
        }
        &#middle {
          stroke-dasharray: 1px 220px;
          stroke-dashoffset: -115px;
        }
      }
    }
  }

  // theme1
  &.theme1 {
    svg {
      path {
        stroke: $color-dark;
      }
    }
  }
 
 
}

/******************************** 
  offcanvas toggler btn END
 ********************************/
