/****************************
 button style 
 ****************************/
// theme btn Normal
.btn {
  font-family: $raleway;
  font-size: 14px;
  font-weight: fontweight(medium);
  line-height: 1;
  text-transform: uppercase;
  @include border-radius(0px);
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
.btn-secondary {
  color: $color-white;
  background: $theme-color;
  border-color: $theme-color;
  &:hover {
    color: $color-white;
    background: $color-dark;
    border-color: $color-dark;
  }
  &:focus {
    box-shadow: none !important;
    outline: 0;
  }
}
.btn-outline-primary {
  color: $color-dark;
  border-color: $color-dark;
  &:hover {
    color: $color-white;
    background: $theme-color;
    border-color: $theme-color;
  }
  &:focus {
    box-shadow: none !important;
    outline: 0;
  }
}

.btn-primary {
  color: $color-white;
  background: $theme-color;
  border-color: $theme-color;
  &:hover {
    background: $color-dark;
    color: $color-white;
    border-color: $color-dark;
  }
  &:focus {
    box-shadow: none !important;
    outline: 0;
  }
}

// theme btn dark

.btn-dark {
  color: $color-white;
  background: $color-dark;
  &:hover {
    color: $color-white;
    border-color: $theme-color;
    background: $theme-color;
  }
  &:focus {
    box-shadow: none !important;
    outline: 0;
  }
}

// btn md
.btn--sm {
  padding: 5px 20px;
}
.btn--md {
  padding: 10px 25px;
}
// btn lg
.btn--lg {
  padding: 15px 30px;
}
// btn xl
.btn--xl {
  padding: 18px 35px;
}
/* border radius in rounded class*/

.rounded {
  @include border-radius(3rem !important);
}

.btn-rounded {
  @include border-radius(0 5px 5px 0);
}

/* custom button styles */

.search-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 100%;
  color: $color-white;
  font-size: 21px;
  text-align: center;
  &.position-left {
    right: auto;
    left: 0;
  }
  @include transition(0.3s);
  &.theme-bg {
    &:hover {
      background-color: $color-dark !important;
      color: $color-white;
    }
  }
  &.bg-dark {
    &:hover {
      background-color: $color-dark2 !important;
      color: $color-white;
    }
  }
  &.bg-white {
    width: 45px;
    font-size: 28px;
    background-color: transparent !important;
    padding: 0;
    color: $color-dark !important;
    &:hover {
      color: $color-dark !important;
    }
  }
}

.news-letter-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: fontweight(regular);
  font-size: 14px;
  color: $color-white;
  padding: 0 20px;
  line-height: 47px;
  height: 47px;
  @include border-radius(0 30px 30px 0);
}
// theme1
.theme1 {
  .news-letter-btn {
    background: $theme-color;
    border: 0px solid $theme-color;
    &:hover {
      background-color: $color-dark;
      color: $color-white;
      border-color: $color-dark;
    }
  }
}


.banner-btn {
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}

// shop-now-btn

.shop-now-btn {
  font-size: 14px;
  font-weight: fontweight(medium);
  line-height: 1;
  padding: 10px 30px;
  color: $color-white;
  background: $theme-color;
  @include border-radius(0px);
  &:hover {
    color: $color-white;
    background: $color-dark;
  }
}

.blog-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: fontweight(regular);
  font-size: 18px;
  color: $color-white;
  padding: 0 20px;
  line-height: 47px;
  height: 100%;
  @include border-radius(0 30px 30px 0);
}

.theme1 {
  .blog-search-btn {
    background: $theme-color;
    border: 1px solid $theme-color;
    &:hover {
      background-color: $color-dark;
      color: $color-white;
      border-color: $color-dark;
    }
  }
}

/****************************
 button style  END
 ****************************/
