/******************************** 
    product Slider
 ********************************/

.product-slider-init {
  .slick-list {
    padding: 15px 0;
    margin: -15px;
    .slick-slide {
      padding: 0 15px;
    }
  }
}

.product-slider2-init {
  .slick-list {
    padding: 15px 0;
    margin: -15px;
    .slick-slide {
      padding: 0 15px;
    }
  }
}
/******************************** 
    product Slider End
 ********************************/
