/************************
   single-product start
 ************************/


.single-product-head {
  .title {
    font-weight: fontweight(semi-bold);
    font-size: 30px;
  }

  .star-content {
    .star-on {
      margin: 0 1px 0 0;
      color: $color-warning;
      font-weight: fontweight(regular);
      font-size: 18px;
    }
    a {
      font-size: 15px;
      font-weight: fontweight(regular);
      span {
        margin-right: 6px;
      }
    }
  }
}

// product-tab-menu single-product

.product-tab-menu {
  &.single-product {
    .nav-item {
      margin-bottom: 30px;
      .nav-link {
        text-transform: capitalize;
        color: $color-dark;
        padding: 0 0 15px 0;
        margin: 0 30px;
        font-size: 24px;
        font-weight: fontweight(semi-bold);
        border: 0px;
        @include max-screen(767) {
          font-size: 18px;
        }
        
        @include border-radius(0px);

        &:hover {
          color: $theme-color;
        }
        &.active {
          color: $theme-color;
        }
      }
    }
  }
}

.single-product-desc {
  background: $color-white;
  padding: 30px;
  border: 1px solid $border-color;
  ul {
    font-size: 15px;
    line-height: 24px;
    text-align: left;
  }
}

.studio-thumb {
  img {
    border: 1px solid $border-color;
  }
  h3 {
    text-transform: capitalize;
    font-weight: fontweight(medium);
    color: $color-dark;
    line-height: 24px;
    font-size: 0.9375rem;
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }
  h6 {
    font-size: 1rem;
    color: $color-dark;
    font-weight: fontweight(medium);
    small {
      font-weight: fontweight(regular);
      font-size: 14px;
      color: $body-color;
    }
  }
}

.product-features {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    li {
      flex: 1 0 40%;

      font-weight: fontweight(regular);
      background: $feature;
      padding: 0.625rem;
      margin-right: 0.625rem;
      min-height: 2.5rem;
      word-break: normal;
      text-transform: capitalize;
      margin-bottom: 0.5rem;
    }
  }
}

.grade-content {
  .grade {
    font-weight: fontweight(medium);
    text-transform: capitalize;
    font-size: 14px;
    color: $instock;
    margin-right: 10px;
  }
  .star-on {
    color: $color-warning;

    font-weight: fontweight(regular);
    font-size: 18px;
  }

  .title {
    font-weight: fontweight(medium);
    text-transform: capitalize;
    font-size: 14px;
    color: $instock;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .sub-title {
    color: $color-dark;
    margin-bottom: 10px;

    font-weight: fontweight(medium);
    line-height: 18px;
    font-size: 14px;
    padding-top: 9px;
    margin-bottom: 10px;
  }
  p {
    line-height: 18px;
    font-size: 14px;
    color: $grey-soft;
  }
}

.group-img {
  img {
    max-width: 80px;
  }
}

.product-grouped {
  .title {
    margin-bottom: 0.8rem;
    color: $color-dark;
    font-size: 15px;
    text-transform: capitalize;
  }
  span {
    font-size: 14px;
    color: $body-color;
    line-height: 1;
  }
}
.product-anotherinfo-wrapper ul li {
  color: #888;
  font-size: 14px;
  list-style: outside none none;
  margin: 0 0 13px;
}
.product-anotherinfo-wrapper ul li span {
  color: $color-dark;
  display: inline-block;
  font-weight: 500;
  margin: 0 26px 0 0;
  min-width: 85px;
}
.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-review .review-top-wrap .review-left .review-name {
  margin-right: 25px;
}

.single-review .review-top-wrap .review-left .review-name h4 {
  font-size: 16px;
  font-weight: 600;
}

.single-review .review-top-wrap .review-left .rating-product {
  line-height: 1;
}

.rating-product {
  margin-bottom: 6px;
}

.rating-product i {
  overflow: hidden;
  font-size: 16px;
  color: #fdd835;
}

.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.review-left a {
  color: #565656;
  margin-left: auto;
}

.single-review .review-bottom p {
  margin: 0;
  max-width: 93%;
}

.ratting-form-wrapper .ratting-form form .star-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 0 20px;
}
.ratting-form-wrapper h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.ratting-form-wrapper .ratting-form form .star-box span {
  margin: 0 15px 0 0;
}

.rating-form-style {
  margin-bottom: 10px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style input,
.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #333;
}

.rating-form-style input {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #253237;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

.rating-form-style {
  margin-bottom: 10px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
}

.rating-form-style input[type="submit"] {
  padding: 0 55px !important;
  line-height: 48px;
  height: 48px;
  width: auto;
  font-size: 15px;
  font-weight: 600;
  border: 0;
  box-shadow: none;
  text-transform: uppercase;
  background: $color-dark !important;
  color: #fff !important;
  transition: all 300ms linear;
  &:hover {
    background: $theme-color !important;
  }
}

/************************
   single-product End
 ************************/
