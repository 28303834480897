/*-----------------------------------------------------------------------------------

    Template Name: Looki - Beauty & Cosmetics eCommerce Bootstrap 5 Template
    Template URI: site.com
    Description: Looki - Beauty & Cosmetics eCommerce Bootstrap 5 Template
    Author: Hastech
    Author URI: site.com
    Version: 1.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

   [ 01. Theme default CSS ]
   [ 02. header CSS ]
   [ 02.1 header sticky CSS ]
   [ 02.2 search-form CSS ]
   [ 02.3 cart-links CSS ]
   [ 02.4 offcanvas-menu-toggler CSS ]
   [ 02.5 offcanvas CSS ]
   [ 02.6 offcanvas-cart CSS ]
   [ 02.7 all-ctry-menu CSS ]
   [ 03. main-slider CSS ]
   [ 03.1 banner CSS ]
   [ 03.2 bread-crumbs CSS ]
   [ 04. tabs-slider CSS ]
   [ 05. product-slider CSS ]
   [ 06. popular-slider CSS ]
   [ 07. featured-slider CSS ]
   [ 08. single-product-sync CSS ]
   [ 09. single-product-sync-nav CSS ]
   [ 09.1 single-product CSS ]
   [ 10. blog-slider CSS ]
   [ 11. brand-slider CSS ]
   [ 12. slick arrow dots CSS ]
   [ 13. product-tags CSS ]
   [ 14. counter CSS ]
   [ 15. check-box CSS ]
   [ 16. static-media CSS ]
   [ 17. media CSS ]
   [ 18. pagination CSS ]
   [ 19. modal CSS ]
   [ 20. Footer CSS ]
   [ 21. footer CSS ]
   [ 22. scroll-up CSS ]
   [ 23. pages ]
   [ 23.1 blogPages ]
   [ 23.2 shopPages ]
   [ 23.3 about-us ]
   [ 23.4 whishlist ]
   [ 23.5 contact-us ]
   [ 23.6 account ]
   [ 23.7 check-out ]
   [ 23.8 compare ]
   [ 23.9 map ]


-----------------------------------------------------------------------------------*/
/**************************** 
[ 01. Theme default CSS ]
*****************************/
/* Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*---------------------------*
 #Template default CSS
*----------------------------*/
body {
  background: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.75;
  color: #565656;
  overflow-x: hidden;
}

@media screen and (max-width: 1199px) {
  .container {
    max-width: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway";
  margin: 0;
  line-height: 1.25;
  color: #111111;
}

p {
  margin: 0px;
  line-height: 1.715;
}

a,
button {
  text-decoration: none;
  color: inherit;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  outline: 0;
}

a,
button,
img,
input {
  transition: all 0.3s;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
  color: #5a5ac9;
}

button,
input[type="submit"] {
  cursor: pointer;
  outline: 0 !important;
}

img {
  max-width: 100%;
}

:focus {
  outline: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

span {
  display: inline-block;
}

span.separator {
  color: #bfbfbf;
  margin: 0 8px;
  line-height: 1;
  font-size: 14px;
}

.zoom-in img {
  transition: all 0.3s linear;
}

.zoom-in:hover img {
  transform: scale(1.045) rotate(0.05deg);
}

.theme-border {
  border: 2px solid #5a5ac9 !important;
}

.theme-border2 {
  border: 2px solid #8a72e0 !important;
}

hr.hr {
  background-color: #ebebeb;
  opacity: 1;
}

span.required {
  color: #5a5ac9;
}

.theme1 .hover-color:hover {
  color: #5a5ac9 !important;
}

.w-md-50 {
  width: 100%;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .w-md-50 {
    max-width: 50%;
  }
}

/*-----------------------
# selection colors
-------------------------*/
*::-moz-selection {
  background: #5a5ac9;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #5a5ac9;
  color: #ffffff;
  text-shadow: none;
}

/*-----------------------
# Input Placeholder
-------------------------*/
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #565656 !important;
  opacity: 1;
}

/*-------------------------- 
input active placeholder 
--------------------------*/
input:active::-webkit-input-placeholder,
textarea:active::-webkit-input-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input:active::-moz-placeholder,
textarea:active::-moz-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input:active:-ms-input-placeholder,
textarea:active:-ms-input-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input:active::-ms-input-placeholder,
textarea:active::-ms-input-placeholder {
  color: #565656 !important;
  opacity: 1;
}

input:active::placeholder,
textarea:active::placeholder {
  color: #565656 !important;
  opacity: 1;
}

body.open {
  overflow-y: scroll;
}

.overlay {
  bottom: 0;
  height: 100vh;
  left: 0;
  margin: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
  transition: all 0.3s cubic-bezier(0.4, 0, 1, 1) 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999999;
  background: rgba(22, 22, 27, 0.97);
  cursor: pointer;
}

.open .overlay {
  opacity: 1;
  visibility: visible;
}

.scale {
  cursor: pointer;
  height: 100%;
  position: absolute;
  width: 100%;
}

.overlay form.search-box {
  bottom: 0;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  max-width: 80%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  transform: scale(1.1);
}

.open .overlay form.search-box {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.overlay .search-box input {
  border: 0 none;
  line-height: 60px;
  width: 100%;
  font-size: 14px;
  background: transparent;
  border: 1px solid #ebebeb;
  padding: 10px 50px 10px 10px;
  height: 60px;
  color: #ffffff;
  text-transform: capitalize;
}

.overlay .search-box input::placeholder {
  color: #ffffff !important;
}

.overlay .search-box button {
  background-color: transparent;
  border: 0 none;
  color: #ffffff;
  font-size: 25px;
  position: absolute;
  right: 20px;
  top: 10px;
  padding: 0;
}

button.close {
  position: absolute;
  font-size: 30px;
  color: #fff;
  top: 40px;
  right: 40px;
  z-index: 33;
  opacity: 1;
}

button.close:hover {
  color: #ffffff !important;
  opacity: 1 !important;
}

/*-------------------------
 background images 
 -------------------------*/
.bg-img {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.bg-img1 {
  background-image: url("../img/slider/slide1.jpg");
}

.bg-img2 {
  background-image: url("../img/slider/slide2.jpg");
}

.bg-img3 {
  background-image: url("../img/slider/slide3.jpg");
}

@media (max-width: 991px) {
  .bg-img {
    background-position: 15% bottom;
  }
  .bg-img.bg-img4 {
    background-position: 45% bottom;
  }
  .wraped .bg-img {
    background-position: 40% bottom;
  }
}

/*------------------------
  background colors 
  ------------------------*/
.theme-bg {
  background-color: #5a5ac9 !important;
}

.theme-bg2 {
  background-color: #8a72e0 !important;
}

.theme-bg3 {
  background-color: #10a341 !important;
}

.bg-light {
  background-color: #efefef !important;
}

.bg-lighten {
  background-color: #f5f5f5 !important;
}

.bg-lighten2 {
  background-color: #f6f6f6;
}

.bg-dark {
  background-color: #111111 !important;
}

/*-------------------
  text-color 
 -------------------*/
.theme-color {
  color: #5a5ac9;
}

.theme-color2 {
  color: #8a72e0;
}

.theme-color3 {
  color: #10a341;
}

.text-warning {
  color: #fdd835 !important;
}

.text-dark {
  color: #111111 !important;
}

.text-muted {
  color: #707070 !important;
}

/*-----------------------
# Margin & Padding
-------------------------*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.py-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.py-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}

.py-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.py-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.py-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.px-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.px-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

/*------------------
 # Margin Top
------------------*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

/*------------------
 # Margin Bottom
------------------*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

/*------------------
 # Margin Left
------------------*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

/*------------------
 # Margin Right
------------------*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

/*------------------
 # Padding Top
------------------*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

/*------------------
 # Padding Bottom
------------------*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

/*---------------
 # Padding Left
---------------*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

/*---------------
 # Padding Right
---------------*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

@media screen and (min-width: 1920px) {
  .px-xl-90 {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media screen and (max-width: 991px) {
  .mr-pe-0 {
    margin-right: 0px;
    padding-right: 0px;
  }
}

/****************************
 button style 
 ****************************/
.btn {
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 0px;
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-secondary {
  color: #ffffff;
  background: #5a5ac9;
  border-color: #5a5ac9;
}

.btn-secondary:hover {
  color: #ffffff;
  background: #111111;
  border-color: #111111;
}

.btn-secondary:focus {
  box-shadow: none !important;
  outline: 0;
}

.btn-outline-primary {
  color: #111111;
  border-color: #111111;
}

.btn-outline-primary:hover {
  color: #ffffff;
  background: #5a5ac9;
  border-color: #5a5ac9;
}

.btn-outline-primary:focus {
  box-shadow: none !important;
  outline: 0;
}

.btn-primary {
  color: #ffffff;
  background: #5a5ac9;
  border-color: #5a5ac9;
}

.btn-primary:hover {
  background: #111111;
  color: #ffffff;
  border-color: #111111;
}

.btn-primary:focus {
  box-shadow: none !important;
  outline: 0;
}

.btn-dark {
  color: #ffffff;
  background: #111111;
}

.btn-dark:hover {
  color: #ffffff;
  border-color: #5a5ac9;
  background: #5a5ac9;
}

.btn-dark:focus {
  box-shadow: none !important;
  outline: 0;
}

.btn--sm {
  padding: 5px 20px;
}

.btn--md {
  padding: 10px 25px;
}

.btn--lg {
  padding: 15px 30px;
}

.btn--xl {
  padding: 18px 35px;
}

/* border radius in rounded class*/
.rounded {
  border-radius: 3rem !important;
}

.btn-rounded {
  border-radius: 0 5px 5px 0;
}

/* custom button styles */
.search-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 100%;
  color: #ffffff;
  font-size: 21px;
  text-align: center;
  transition: all 0.3s;
}

.search-btn.position-left {
  right: auto;
  left: 0;
}

.search-btn.theme-bg:hover {
  background-color: #111111 !important;
  color: #ffffff;
}

.search-btn.bg-dark:hover {
  background-color: #292929 !important;
  color: #ffffff;
}

.search-btn.bg-white {
  width: 45px;
  font-size: 28px;
  background-color: transparent !important;
  padding: 0;
  color: #111111 !important;
}

.search-btn.bg-white:hover {
  color: #111111 !important;
}

.news-letter-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 0 20px;
  line-height: 47px;
  height: 47px;
  border-radius: 0 30px 30px 0;
}

.theme1 .news-letter-btn {
  background: #5a5ac9;
  border: 0px solid #5a5ac9;
}

.theme1 .news-letter-btn:hover {
  background-color: #111111;
  color: #ffffff;
  border-color: #111111;
}

.banner-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shop-now-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 10px 30px;
  color: #ffffff;
  background: #5a5ac9;
  border-radius: 0px;
}

.shop-now-btn:hover {
  color: #ffffff;
  background: #111111;
}

.blog-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  padding: 0 20px;
  line-height: 47px;
  height: 100%;
  border-radius: 0 30px 30px 0;
}

.theme1 .blog-search-btn {
  background: #5a5ac9;
  border: 1px solid #5a5ac9;
}

.theme1 .blog-search-btn:hover {
  background-color: #111111;
  color: #ffffff;
  border-color: #111111;
}

/****************************
 button style  END
 ****************************/
/*******************************
 section-title style 
 *******************************/
.section-title {
  padding-bottom: 50px;
}

.section-title .title {
  position: relative;
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
}

@media screen and (max-width: 480px) {
  .section-title .title {
    font-size: 20px;
  }
}

.section-title .title::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0px;
  width: 60px;
  height: 2px;
  content: "";
  background: #5a5ac9;
}

/*******************************
 section-title style  End
 *******************************/
/*******************************
    badge style 
 *******************************/
.badge {
  position: absolute;
  text-align: center;
  padding: 5px;
  line-height: 1;
  font-size: 12px;
  z-index: 2;
  font-weight: 500;
  text-transform: capitalize;
}

.theme1 .badge-danger {
  color: #ffffff;
  background-color: #5a5ac9 !important;
}

.badge-success {
  color: #ffffff;
  background-color: #10a341 !important;
}

.cbdg1 {
  width: 20px;
  height: 20px;
  bottom: 10px;
  left: 15px;
  padding: 0px;
  line-height: 18px;
  border-radius: 50%;
}

.theme1 .cbdg1 {
  color: #ffffff;
  background-color: #5a5ac9 !important;
}

.cb6 {
  display: inline-block;
  text-align: center;
  font-size: 18px;
  padding: 10px;
}

.top-left, .top-right {
  top: 10px;
  left: 10px;
}

.top-right {
  left: auto;
  right: 10px;
}

/*******************************
    badge style  END
 *******************************/
/**************************** 
[ 02. header CSS ]
*****************************/
/*------------------*
# Top Nave
*------------------*/
.static-info p {
  font-size: 14px;
  line-height: 14px;
}

.navbar-top li {
  position: relative;
}

.navbar-top li img {
  margin-right: 5px;
}

.navbar-top li a {
  font-weight: 400;
  text-transform: capitalize;
  padding: 0 15px;
  line-height: 14px;
  font-size: 14px;
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .navbar-top li a {
    padding: 0 8px;
  }
}

.navbar-top li.english .topnav-submenu {
  right: 0 !important;
  left: auto !important;
}

.theme1 .navbar-top ul li a {
  color: #ffffff;
}

.theme1 .navbar-top ul li a:hover {
  color: #5a5ac9;
}

.theme1 .navbar-top.modify ul li a {
  color: #ffffff;
}

.theme1 .navbar-top.modify ul li a:hover {
  color: #ffffff;
}

/* topnav-submenu */
.topnav-submenu {
  top: 50px !important;
  right: 15px !important;
  left: auto !important;
  min-width: 130px;
  overflow: hidden;
  padding: 0 15px;
  background: #ffffff;
  z-index: 3;
  border-radius: 0;
  transform: translate3d(0px, 0px, 0px) !important;
  margin: 0;
  border: 0;
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: ease-in 0.3s;
  -webkit-transition: ease-in 0.3s;
  -moz-transition: ease-in 0.3s;
  -ms-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
}

.topnav-submenu.show {
  top: 40px !important;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 767px) {
  .topnav-submenu.show {
    top: 30px !important;
  }
}

@media screen and (max-width: 991px) {
  .topnav-submenu {
    right: auto !important;
    left: 0 !important;
  }
}

.topnav-submenu li a {
  display: block;
  padding: 10px;
  line-height: 25px;
  font-size: 13px;
  border-bottom: 1px solid #ebebeb;
}

.topnav-submenu li:last-child a {
  border-bottom: 0px;
}

.theme1 .topnav-submenu li a {
  color: #556655 !important;
}

.theme1 .topnav-submenu li a:hover {
  color: #5a5ac9 !important;
}

.theme1 .topnav-submenu li.active a {
  color: #5a5ac9 !important;
}

/*------------------*
# Top Nave ENd
*------------------*/
/*------------------*
# Main-menu
*------------------*/
.main-menu li {
  margin: 0 1px;
  position: relative;
}

.main-menu li a {
  font-weight: 600;
  display: block;
  padding: 20px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  text-transform: uppercase;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu li a {
    padding: 15px;
    font-size: 13px;
  }
}

.main-menu li a i {
  font-size: 15px;
  position: relative;
  top: 1px;
}

.theme1 li a {
  color: #111111;
}

.theme1 li:hover > a {
  color: #5a5ac9;
}

.theme1 li.active > a {
  color: #5a5ac9;
}

/*------------------*
# Main-menu END
*------------------*/
/*------------------*
# mega-menu
*------------------*/
.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 3;
  background: #ffffff;
  padding: 30px 15px;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  margin: 0 15px;
  transform-origin: 0 0;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
}

.main-menu li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s, opacity 0.3s;
  transform: rotateX(0deg);
}

.mega-menu > li {
  margin: 0;
}

.mega-menu li ul li {
  margin: 0;
}

.mega-menu li > ul li:last-child a {
  padding-bottom: 0;
}

.mega-menu li > ul li > a:hover {
  padding-left: 5px;
}

.mega-menu li ul li.mega-menu-title {
  margin-bottom: 20px;
}

.mega-menu li ul li.mega-menu-title a {
  color: #111111;
  line-height: 1;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 0 !important;
}

.mega-menu li a {
  text-align: left;
  padding: 0 0 5px;
  color: #565656;
  text-transform: capitalize;
  line-height: 28px;
  font-weight: 400;
  font-size: 14px;
}

.mega-menu-custom-with {
  max-width: 1110px;
  min-width: 1110px;
  left: 23%;
}

@media screen and (max-width: 1200px) {
  .mega-menu-custom-with {
    min-width: 95%;
    left: 0%;
    right: 0%;
  }
}

.theme1 .mega-menu li ul li.mega-menu-title a:hover {
  color: #5a5ac9;
}

.blog-ctry-menu li a {
  position: relative;
  font-weight: 400;
  color: #565656;
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 35px;
}

.blog-ctry-menu li a::before {
  content: "\f2c7";
  position: absolute;
  display: inline-block;
  font-family: "Ionicons";
  right: 0;
}

.blog-ctry-menu li.active a::before {
  content: "\f462";
}

.blog-ctry-menu li ul.category-sub-menu {
  display: none;
}

.blog-ctry-menu li ul.category-sub-menu li a {
  font-size: 13px;
  line-height: 35px;
  padding: 0 10px;
  display: block;
  color: #707070;
}

.blog-ctry-menu li ul.category-sub-menu li a::before {
  content: "";
}

/*------------------*
# mega-menu END
*------------------*/
/*------------------*
# sub-menu
*------------------*/
.sub-menu {
  position: absolute;
  padding: 0;
  min-width: 240px;
  z-index: 4;
  box-shadow: 0 0 3.76px 0.24px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  transform-origin: 0 0;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
}

.main-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transition: transform 0.3s, opacity 0.3s;
  transform: rotateX(0deg);
}

.sub-menu li {
  padding: 0 20px;
  border-bottom: 1px solid #eee;
  margin: 0;
}

.sub-menu li a {
  font-weight: 400;
  text-align: left;
  text-transform: capitalize;
  padding: 0;
  color: #565656;
  font-size: 14px;
  line-height: 40px;
}

.sub-menu li a i {
  margin-left: 10px;
}

.sub-menu li:hover > a {
  padding-left: 5px;
}

.sub-menu .sub-menu {
  opacity: 0;
  visibility: hidden;
  left: 100%;
  top: 0;
  transform-origin: 0 0;
  transform-style: preserve-3d;
  transform: rotateX(-75deg);
}

/*------------------*
# sub-menu END
*------------------*/
/**************************** 
[ 02.1 header sticky CSS ]
*****************************/
/*******************************
 sticky header
 *******************************/
#sticky.is-isticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.3s;
  animation: ease-in-out 0.5s fadeInDown;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
}

.theme1.is-isticky {
  background: #ffffff;
}

.theme1.is-isticky:hover {
  background: #ffffff;
}

#sticky.custom-sticky.is-isticky {
  padding-top: 4px;
  padding-bottom: 4px;
}

@media screen and (max-width: 991px) {
  #sticky.custom-sticky.is-isticky {
    position: static !important;
  }
}

/*******************************
 sticky header End
 *******************************/
/**************************** 
[ 02.2 search-form CSS ]
*****************************/
/* ***************
 search-form
******************/
.search-form .form-control {
  padding: 10px 70px 10px 20px;
  width: 100%;
  height: 46px;
  font-size: 14px;
  background: #ffffff;
  color: #111111;
  border: 2px solid transparent;
  border-radius: 5px;
}

.search-form .form-control:focus {
  box-shadow: none;
}

.search-form .form-control.theme1-border {
  border-color: #eee;
}

.search-body {
  position: absolute;
  width: 600px;
  top: 40px;
  right: 255px;
  display: none;
  z-index: 2;
}

@media screen and (max-width: 1500px) {
  .search-body {
    right: 220px;
  }
}

@media screen and (max-width: 1100px) {
  .search-body {
    right: 205px;
  }
}

.width-calc-from-left {
  width: calc(100% - 220px);
}

@media screen and (max-width: 991px) {
  .width-calc-from-left {
    width: calc(100%);
    padding-left: 0px;
  }
}

/* ***************
 search-form End
******************/
/**************************** 
[ 02.3 cart-links CSS ]
*****************************/
/*************************************
   cart-block-links CSS 
*************************************/
.cart-block-links li {
  margin-right: 30px;
}

.cart-block-links li a {
  font-size: 20px;
  font-family: "Raleway";
  font-weight: 500;
  text-align: center;
  line-height: 25px;
}

@media screen and (max-width: 767px) {
  .cart-block-links li a {
    font-size: 20px;
  }
}

.cart-block-links.theme1 a {
  color: #111111;
}

.cart-block-links.theme1 a:hover {
  color: #5a5ac9;
}

/*************************************
   cart-block-links CSS  END
*************************************/
/*********************************** 
[ 02.4 offcanvas-menu-toggler CSS ]
************************************/
/******************************** 
  offcanvas toggler btn
 ********************************/
.mobile-menu-toggle svg {
  width: 50px;
  position: relative;
}

@media screen and (min-width: 576px) {
  .mobile-menu-toggle svg {
    top: -8px;
  }
}

.mobile-menu-toggle svg path {
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  fill: none;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 30px;
}

.mobile-menu-toggle svg path#top, .mobile-menu-toggle svg path#bottom {
  stroke-dasharray: 240px 950px;
}

.mobile-menu-toggle svg path#middle {
  stroke-dasharray: 240px 240px;
}

.mobile-menu-toggle .close svg path#top, .mobile-menu-toggle .close svg path#bottom {
  stroke-dasharray: -650px;
  stroke-dashoffset: -650px;
}

.mobile-menu-toggle .close svg path#middle {
  stroke-dasharray: 1px 220px;
  stroke-dashoffset: -115px;
}

.mobile-menu-toggle.theme1 svg path {
  stroke: #111111;
}

/******************************** 
  offcanvas toggler btn END
 ********************************/
/**************************** 
[ 02.5 offcanvas CSS ]
*****************************/
/******************************** 
 offcanvas menu style
 ********************************/
body.offcanvas-open {
  overflow-y: hidden;
  padding-right: 17px;
}

@media screen and (max-width: 991px) {
  body.offcanvas-open {
    padding-right: 0px;
  }
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas {
  visibility: visible;
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  overflow: hidden;
  width: 400px;
  height: 100vh;
  transform: translateX(100%);
  background-color: #ffffff;
  box-shadow: none;
  transition: all 0.5s ease-in-out;
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas.offcanvas-mobile-menu {
  right: auto;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-mobile-menu.offcanvas-open {
  transform: translateX(0);
}

@media screen and (max-width: 480px) {
  .offcanvas {
    width: 300px;
  }
}

.offcanvas .inner {
  padding: 20px;
}

@media screen and (max-width: 991px) {
  .offcanvas .inner {
    overflow-y: scroll;
    height: 660px;
  }
}

@media screen and (max-width: 767px) {
  .offcanvas .inner {
    height: calc(100% - 30px);
  }
}

@media screen and (max-width: 480px) {
  .offcanvas .inner {
    padding: 10px;
  }
}

.offcanvas .title {
  color: #111111;
  font-weight: 500;
}

.offcanvas .offcanvas-close {
  position: relative;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas .offcanvas-close::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 20px;
  height: 2px;
  content: "";
  transition: all 0.5s ease-in-out;
  transform: rotate(-45deg);
  background-color: #111111;
}

.offcanvas .offcanvas-close:hover:after {
  transform: rotate(0deg);
}

.offcanvas .offcanvas-close::before {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 20px;
  height: 2px;
  content: "";
  transition: all 0.5s ease-in-out;
  transform: rotate(45deg);
  background-color: #111111;
}

.offcanvas .offcanvas-close:hover:before {
  transform: rotate(180deg);
}

.offcanvas .offcanvas-menu ul li {
  position: relative;
  display: block;
  line-height: 28px;
}

.offcanvas .offcanvas-menu ul li a {
  display: block;
  position: relative;
  padding: 8px 20px;
  text-transform: capitalize;
  border-top: 1px solid #eee;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu {
  position: static;
  top: auto;
  display: none;
  visibility: visible;
  width: 100%;
  min-width: auto;
  transition: none;
  opacity: 1;
  box-shadow: none;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu li {
  line-height: inherit;
  position: relative;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu li a {
  font-weight: 400;
  padding-left: 40px;
}

.offcanvas .offcanvas-menu ul li .offcanvas-submenu li ul a {
  padding-left: 60px;
}

.offcanvas .offcanvas-menu ul li .menu-expand {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 24px;
  height: 44px;
  cursor: pointer;
  background-color: transparent;
}

.offcanvas .offcanvas-menu ul li .menu-expand::before, .offcanvas .offcanvas-menu ul li .menu-expand::after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 14px;
  height: 2px;
  content: "";
  transition: all 0.5s ease-in-out;
  background-color: #111111;
}

.offcanvas .offcanvas-menu ul li .menu-expand::after {
  transform: rotate(90deg);
}

.offcanvas .offcanvas-menu ul li.active > .menu-expand::after {
  transform: rotate(0) scale(0.75);
}

.offcanvas .offcanvas-social li {
  display: inline-block;
  margin: 0 10px 10px 0;
  line-height: 40px;
  padding: 0;
}

.offcanvas .offcanvas-social li a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  border: 2px solid transparent;
  z-index: 1;
}

.theme1 .offcanvas-menu li:hover > .menu-expand:after {
  background: #5a5ac9;
}

.theme1 .offcanvas-menu li:hover > .menu-expand:before {
  background: #5a5ac9;
}

.theme1 .offcanvas-social a {
  color: #ffffff;
  background: #5a5ac9;
}

.theme1 .offcanvas-social a:hover {
  background: transparent;
  color: #5a5ac9;
  border-color: #5a5ac9;
}

/******************************** 
 offcanvas menu style ENd
 ********************************/
/**************************** 
[ 02.6 offcanvas-cart CSS ]
*****************************/
/******************************** 
 offcanvas cart style
 ********************************/
.offcanvas .inner .head {
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.offcanvas .inner .head .offcanvas-close {
  position: relative;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas .inner .sub-total {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eee;
  margin: 30px 0;
  border-bottom: 1px solid #eee;
}

.offcanvas .inner .sub-total strong {
  color: #111111;
  font-weight: 500;
}

.offcanvas .inner .sub-total .amount {
  font-weight: 500;
}

.offcanvas .inner .minicart-message {
  margin-top: 15px;
  color: #111111;
  margin-bottom: 15px;
}

.minicart-product-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.minicart-product-list li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ebebeb;
}

.minicart-product-list li .image {
  flex: 1 0 0px;
}

.minicart-product-list li .image img {
  max-width: 100%;
  border: 1px solid #ebebeb;
}

@media screen and (max-width: 575px) {
  .minicart-product-list li .image {
    flex: 1 0 50px;
  }
}

.minicart-product-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.minicart-product-list li .content {
  position: relative;
  flex: 1 0 calc(100% - 150px);
  padding-left: 15px;
}

.minicart-product-list li .content .title {
  color: #111111;
  line-height: 18px;
  margin-bottom: 10px;
  font-size: 15px;
  text-transform: capitalize;
}

.minicart-product-list li .content .quantity-price {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

.minicart-product-list li .content .quantity-price .amount {
  color: #111111;
  font-weight: 500;
}

.minicart-product-list li .content .remove {
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 3px;
  color: #111111;
  font-size: 18px;
}

@media screen and (max-width: 575px) {
  .minicart-product-list li .content {
    flex: 1 0 calc(100% - 75px);
  }
}

.theme1 .inner .sub-total .amount {
  color: #5a5ac9;
}

.theme1 .minicart-product-list li .content .title:hover {
  color: #5a5ac9;
}

.theme1 .minicart-product-list li .content .remove:hover {
  color: #5a5ac9;
}

/******************************** 
 offcanvas cart style ENd
 ********************************/
/**************************** 
[ 02.7 all-ctry-menu CSS ]
*****************************/
/*******************************
    hero-side-category style 
 *******************************/
.hero-side-category {
  width: 100%;
  position: relative;
}

.hero-side-category .category-toggle {
  padding: 10px 20px;
  background-color: #ffffff;
  color: #111111;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  text-align: left;
  border: 2px solid #eee;
  border-radius: 5px;
  position: relative;
}

.hero-side-category .category-toggle i {
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  font-size: 18px;
  transform: translate(0, 2px);
}

.hero-side-category .category-menu {
  background-color: #ffffff;
  width: 100%;
  left: 0;
  top: 110%;
  border: 1px solid #ebebeb;
  position: absolute;
  display: none;
  z-index: 2;
  height: 500px;
  overflow-x: auto;
  border-radius: 5px;
}

.hero-side-category .category-menu li a {
  display: block;
  padding: 10px 25px;
  line-height: 30px;
  font-size: 14px;
  color: #253237;
  font-weight: 400;
  position: relative;
  border-bottom: 1px solid #ebebeb;
}

.hero-side-category .category-menu li a i {
  position: absolute;
  right: 30px;
}

.hero-side-category .category-menu li .category-mega-menu {
  display: none;
}

.hero-side-category .category-menu li .category-mega-menu a {
  padding: 10px 25px 10px 35px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;
  color: #253237;
  position: relative;
  margin: 0;
  text-transform: inherit;
}

.hero-side-category .category-menu li #more-btn {
  font-weight: 500;
  padding-left: 45px;
}

.hero-side-category .category-menu li #more-btn i {
  right: auto;
  left: 30px;
}

.theme1 .category-menu li:hover > a {
  color: #5a5ac9;
}

/***********************************
    hero-side-category style  END
 ***********************************/
/*------------------*
# Main Content start
*------------------*/
/******************************** 
   product-tags style
 ********************************/
.product-size .title {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #111111;
  line-height: 24px;
}

.product-size select {
  background-color: #ffffff;
  padding: 0 30px 0 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  color: #111111;
  border: 1px solid #ebebeb;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=) no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
  margin-top: 20px;
}

.product-size select option {
  font-weight: 400;
}

.select-arrow-down {
  position: absolute;
  left: calc(100% - 85px);
}

/* product-widget  */
.product-widget .title {
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.product-widget .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #5a5ac9;
}

.product-tag li a {
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  padding: 10px 25px;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
  border: 1px solid #ebebeb;
  background: #ffffff;
  margin: 4px;
  margin-left: 4px;
}

.product-tag li a:hover {
  color: #ffffff;
  background: #5a5ac9;
  border-color: #5a5ac9;
}

/******************************** 
   product-tags style END
 ********************************/
/* *******************
 counter style
**********************/
.product-count.style input {
  color: #111111;
  background-color: #ffffff;
  height: 54px;
  padding: 10px 30px 10px 10px;
  width: 80px;
  border: 1px solid #ebebeb;
  display: block;
  text-align: center;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.product-count.style input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.product-count.style input[type="number"]::-webkit-inner-spin-button, .product-count.style input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.product-count.style .count-btn {
  background-color: transparent;
  border: 0px;
  border-left: 1px solid #ebebeb;
  display: block;
  width: 20px;
  height: 27px;
  padding: 0px;
  text-align: center;
  color: #111111;
  font-size: 10px;
  transform: translateX(-100%);
  user-select: none;
}

.product-count.style .increment {
  border-bottom: 1px solid #ebebeb;
}

/* *******************
 counter style End
**********************/
/*************************************
    check-box style CSS 
*************************************/
.widget-check-box {
  margin-bottom: 10px;
}

.sidbar-widget {
  padding-top: 20px;
}

.sidbar-widget .title {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.sidbar-widget .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #5a5ac9;
}

.sidbar-widget .sub-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.widget-check-box input[type="checkbox"] {
  display: none;
}

.widget-check-box input[type="checkbox"] + label {
  position: relative;
  padding-top: 3px;
  padding-left: 30px;
  cursor: pointer;
  text-transform: capitalize;
  user-select: none;
  font-size: 14px;
  line-height: 22px;
}

.widget-check-box input[type="checkbox"] + label:hover {
  color: #5a5ac9;
}

.widget-check-box input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 5px;
  opacity: 1;
}

.widget-check-box input[type="checkbox"] + label:hover:before {
  border-color: #5a5ac9;
}

.widget-check-box.color-grey input[type="checkbox"] + label:before {
  background-color: #7e7e7e;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.widget-check-box.color-grey input[type="checkbox"]:checked + label:before {
  background-color: transparent;
}

.widget-check-box.color-white input[type="checkbox"] + label:before {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.widget-check-box.color-black input[type="checkbox"] + label:before {
  background-color: #666;
  border: 1px solid #666;
}

.widget-check-box.color-camel input[type="checkbox"] + label:before {
  background-color: #c19a6b;
  border: 1px solid #c19a6b;
}

.widget-check-box.color-camel input[type="checkbox"]:checked + label:before {
  background-color: transparent;
}

.widget-check-box input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -1px;
  left: 5px;
  border-color: #5a5ac9;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.theme1 .widget-check-box input[type="checkbox"]:checked + label:before {
  border-color: #5a5ac9;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

.widget-check-box.color-black input[type="checkbox"]:checked + label:before {
  background: 0 0;
}

/* radio check box style */
.widget-check-box input[type="radio"]:checked,
.widget-check-box input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.widget-check-box input[type="radio"]:checked + label,
.widget-check-box input[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

.widget-check-box input[type="radio"]:checked + label:before,
.widget-check-box input[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ebebeb;
  border-radius: 100%;
  background: #ffffff;
}

.widget-check-box input[type="radio"]:checked + label:after,
.widget-check-box input[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #5a5ac9;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
}

.widget-check-box input[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.widget-check-box input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.widget-check-box input[type="radio"] + label:hover {
  color: #5a5ac9;
}

/* check-box style CSS End */
#amount {
  border: 0;
  color: #565656;
  font-size: 14px;
  margin: 0 0 20px;
}

.sidbar-widget .ui-widget-content {
  border: 1px solid #5a5ac9;
}

.sidbar-widget .ui-slider-horizontal {
  height: 5px;
}

.sidbar-widget .ui-widget-header {
  background: #5a5ac9;
}

.sidbar-widget .ui-slider-handle {
  top: 50%;
  width: 15px;
  height: 15px;
  border: none;
  background: #ffffff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
  cursor: pointer;
  margin-top: -7.5px;
  border-radius: 100%;
}

/*************************************
    check-box style CSS  END
*************************************/
/******************************** 
     static media Css
  ********************************/
.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.static-media-wrap {
  padding: 45px 30px;
}

.static-media2 .title {
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 700;
  color: #ffffff;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .static-media2 .title {
    font-size: 15px;
  }
}

.static-media2 .text {
  color: #ffffff;
}

/******************************** 
     static media Css End
  ********************************/
/*************************************
 media CSS 
*************************************/
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .static-media.me-50 {
    margin-right: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .static-media.me-50 {
    margin-right: 30px;
  }
}

.phone {
  line-height: 1;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .phone {
    font-size: 13px;
  }
}

.phone a:hover {
  color: #5a5ac9 !important;
}

.phone span {
  line-height: 1;
  font-size: 14px;
}

.phone strong {
  line-height: 1;
}

.phone .email {
  line-height: 2rem;
}

.theme1 .phone a:hover {
  color: #5a5ac9 !important;
}

.theme1 .phone.modify a:hover {
  color: #ffffff !important;
}

/*************************************
 media CSS  ENd
*************************************/
/******************************** 
    slick arrow style
 ********************************/
.slick-arrow {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 50%;
  border: 0px;
  background: #111111;
  color: #ffffff;
  text-align: center;
  z-index: 1;
  font-size: 14px;
  line-height: 50px;
  transform: translateY(-50%);
  border-radius: 50%;
}

.main-slider .slick-prev {
  left: 40px;
  background: #111111;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
}

.main-slider .slick-next {
  right: 40px;
  background: #111111;
  color: #ffffff;
  opacity: 0;
  visibility: hidden;
}

.main-slider:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.slick-nav .slick-prev {
  left: -25px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 1199px) {
  .slick-nav .slick-prev {
    left: 0px;
  }
}

.slick-nav:hover .slick-prev {
  opacity: 1;
  visibility: visible;
}

.slick-nav .slick-next {
  right: -25px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 1199px) {
  .slick-nav .slick-next {
    right: 0px;
  }
}

.slick-nav:hover .slick-next {
  visibility: visible;
  opacity: 1;
}

.slick-nav-sync .slick-prev {
  left: -15px;
  opacity: 0;
  visibility: hidden;
}

.slick-nav-sync:hover .slick-prev {
  left: 15px;
  opacity: 1;
  visibility: visible;
}

.slick-nav-sync .slick-next {
  right: -15px;
  opacity: 0;
  visibility: hidden;
}

.slick-nav-sync:hover .slick-next {
  right: 15px;
  visibility: visible;
  opacity: 1;
}

.slick-nav-brand .slick-prev {
  left: -40px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 1024px) {
  .slick-nav-brand .slick-prev {
    left: -15px;
  }
}

.slick-nav-brand:hover .slick-prev {
  opacity: 1;
  visibility: visible;
}

.slick-nav-brand .slick-next {
  right: -40px;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 1024px) {
  .slick-nav-brand .slick-next {
    right: -15px;
  }
}

.slick-nav-brand:hover .slick-next {
  visibility: visible;
  opacity: 1;
}

.theme1 .slick-arrow:hover {
  color: #ffffff;
  background: #5a5ac9;
  border-color: #5a5ac9;
}

/* slick dots style */
.dots-style .slick-dots {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dots-style .slick-dots li {
  line-height: 1;
}

.dots-style .slick-dots li button {
  width: 14px;
  height: 14px;
  text-indent: 2000px;
  font-size: 0px;
  display: block;
  margin: 0 5px;
  transition: all 0.3s;
  border-radius: 50%;
}

.dots-style .slick-dots li button:hover {
  transform: scale3d(1, 1, 1);
}

.popular-slider-init.dots-style .slick-dots {
  bottom: 0px;
  position: static;
  margin-top: 30px;
}

.popular-slider-init.dots-style .slick-dots li button {
  width: 8px;
  height: 8px;
  transition: all 0.3s linear;
}

.theme1 .slick-dots li button {
  background: #111111;
}

.theme1 .slick-dots li button:hover {
  background: #5a5ac9;
}

.theme1 .slick-dots li.slick-active button {
  background: #5a5ac9;
}

.theme1 .popular-slider-init.dots-style .slick-dots li button {
  background: #111111;
}

.theme1 .popular-slider-init.dots-style .slick-dots li button:hover {
  background: #5a5ac9;
}

.theme1 .popular-slider-init.dots-style .slick-dots li.slick-active button {
  width: 28px;
  background: #5a5ac9;
  border-radius: 8px;
}

/******************************** 
    slick arrow style ENd
 ********************************/
/******************************** 
    Main Slider
 ********************************/
.slick-slider {
  background: transparent;
}

.slider-height {
  height: 855px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .slider-height {
    height: 595px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height {
    height: 595px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height {
    height: 450px;
  }
}

@media screen and (max-width: 767px) {
  .slider-height {
    height: 300px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height {
    height: 450px;
  }
}

.slider-height2 {
  height: 714px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .slider-height2 {
    height: 600px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height2 {
    height: 480px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height2 {
    height: 400px;
  }
}

@media screen and (max-width: 767px) {
  .slider-height2 {
    height: 300px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height2 {
    height: 350px;
  }
}

.slider-content-width {
  max-width: 52%;
}

@media screen and (min-width: 768px) and (max-width: 1500px) {
  .slider-content-width {
    max-width: 52%;
  }
}

@media screen and (max-width: 576px) {
  .slider-content-width {
    max-width: 100%;
  }
}

.slider-content .title {
  font-weight: 400;
  font-size: 65px;
  text-transform: capitalize;
}

.slider-content .title span {
  opacity: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .slider-content .title {
    font-size: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .title {
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .title {
    font-size: 35px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content .title {
    font-size: 30px;
  }
}

.slider-content .text {
  font-size: 20px;
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 15px;
  opacity: 0;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content .text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .slider-content .text.mb-25 {
    margin-bottom: 15px;
  }
}

.slider-content .btn {
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .slider-content .btn.mt-45 {
    margin-top: 30px;
  }
}

/******************************** 
# main slider End
********************************/
/****************************
#Common Banner
****************************/
.banner-thumb img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .banner-thumb img {
    width: 100%;
  }
}

/* hover-animation */
.hover-animation a::before {
  position: absolute;
  top: 0;
  left: 50%;
  right: 51%;
  bottom: 0;
  content: "";
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}

.hover-animation a::after {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 50%;
  content: "";
  background: rgba(255, 255, 255, 0.2);
  opacity: 1;
}

.hover-animation:hover a::before {
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.9s linear;
}

.hover-animation:hover a::after {
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: all 0.9s linear;
}

/******************************** 
#common-banner End
********************************/
/****************************
breadcrumb-section
 ****************************/
@media screen and (max-width: 767px) {
  .breadcrumb-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .breadcrumb-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.breadcrumb-item {
  text-transform: capitalize;
  line-height: 1;
  padding: 0px !important;
}

@media screen and (max-width: 450px) {
  .breadcrumb-item {
    margin: 5px 0px;
  }
}

.breadcrumb-item a {
  display: inline-block;
  position: relative;
  color: #111111 !important;
  line-height: 1;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f3d3";
  color: #707070 !important;
  margin: 0 10px;
  content: "\f3d3";
  font-family: "Ionicons";
  padding: 0px !important;
  position: relative;
  top: 2px;
  font-size: 14px;
}

.theme1 .breadcrumb-item a:hover {
  color: #5a5ac9 !important;
}

.theme1 .breadcrumb-item.active {
  color: #5a5ac9;
}

.breadcrumb-section .section-title {
  padding-bottom: 30px;
}

/****************************
breadcrumb-section END
 ****************************/
/******************************** 
    product tab menu style
 ********************************/
.product-tab-menu .nav-item {
  margin: 0px 2px;
}

@media screen and (max-width: 560px) {
  .product-tab-menu .nav-item {
    margin: 0px 2px 5px;
  }
}

.product-tab-menu .nav-item .nav-link {
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  padding: 8px 15px;
  color: #111111;
}

.theme1 .nav-link.active, .theme1 .nav-link:hover {
  color: #5a5ac9;
  background: transparent;
  text-decoration: underline;
}

/******************************** 
    product tab menu style END
 ********************************/
/******************************** 
    product links style
 ********************************/
.actions {
  position: absolute;
  z-index: 3;
  width: 100%;
  bottom: 30px;
}

@media screen and (min-width: 768px) {
  .actions {
    bottom: 20px;
  }
}

.actions li {
  margin: 0 3px;
}

.actions li .action {
  display: block;
  background: #f6f6f6;
  color: #111111;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  transform: scale3d(0, 0, 0);
  border-radius: 50%;
}

.actions li .action span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
}

.product-card .card-body:hover .actions li a {
  transform: scale3d(1, 1, 1);
}

.product-card .card-body:hover .actions li:nth-child(2) a {
  transition: all 0.6s;
}

.product-card .card-body:hover .actions li:nth-child(3) a {
  transition: all 0.9s;
}

.theme1 .actions li:hover .action {
  background: #5a5ac9;
  color: #ffffff;
}

/******************************** 
    product links style END
 ********************************/
/******************************** 
   product-card style 
 ********************************/
.tab-content .tab-pane.active .product-list {
  animation: zoomIn 0.5s ease;
  animation-duration: 1s;
}

.product-thumbnail img {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

img.second-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: #ffffff;
}

.product-card {
  border: 0;
  box-shadow: 0 0 11.83px 1.17px rgba(0, 0, 0, 0.06);
}

.product-card:hover img.second-img {
  opacity: 1;
  z-index: 1;
}

.product-card.no-shadow {
  box-shadow: none;
}

/* product desc style */
.product-desc {
  padding: 18px;
}

.product-desc .title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

@media screen and (max-width: 991px) {
  .product-desc .title {
    font-size: 14px;
  }
}

.theme1 .product-desc .title:hover a {
  color: #5a5ac9;
}

.star-rating span {
  font-weight: 400;
  line-height: 1;
  margin: 0 1px 0 0;
  font-size: 18px;
  color: #fdd835;
}

.star-rating span .de-selected {
  color: #eee;
}

.product-price {
  font-weight: 700;
  font-size: 16px;
  color: #111111;
}

.product-price .del {
  margin-right: 2px;
}

.theme1 .product-price .onsale {
  color: #5a5ac9;
}

.pro-btn {
  background: #f6f6f6;
  color: #111111;
  width: 40px;
  height: 40px;
  text-align: center;
  font-weight: 400;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pro-btn-right {
  position: absolute;
  right: 20px;
}

.theme1 .pro-btn:hover {
  background: #5a5ac9;
  color: #ffffff;
}

@media screen and (max-width: 480px) {
  .product-thumbnail {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .product-thumbnail a {
    display: block;
  }
  .product-thumbnail a img {
    width: 100%;
  }
}

/******************************** 
   product-card style END
 ********************************/
/******************************** 
    product Slider
 ********************************/
.product-slider-init .slick-list {
  padding: 15px 0;
  margin: -15px;
}

.product-slider-init .slick-list .slick-slide {
  padding: 0 15px;
}

.product-slider2-init .slick-list {
  padding: 15px 0;
  margin: -15px;
}

.product-slider2-init .slick-list .slick-slide {
  padding: 0 15px;
}

/******************************** 
    product Slider End
 ********************************/
/******************************** 
    popular Slider
 ********************************/
.popular-slider-init .slick-list {
  padding: 20px 0px;
  margin: 0 -15px;
}

.popular-slider-init .slick-list .slick-slide {
  padding: 0 15px;
}

.popular-card {
  text-align: center;
  background: #ffffff;
  border: 0;
  box-shadow: 0 0 11.83px 1.17px rgba(0, 0, 0, 0.06);
  border-radius: 0px;
}

.popular-card .card-body {
  padding: 20px;
}

.popular-card .card-body .thumb-naile {
  display: block;
  overflow: hidden;
}

.popular-card .card-body .popular-title {
  text-transform: uppercase;
  padding: 20px 0px 0px;
  font-weight: 600;
  font-size: 14px;
}

.popular-card .card-body .popular-title a {
  display: block;
}

.theme1 .popular-card {
  text-align: center;
}

.theme1 .popular-card .card-body .popular-title a:hover {
  color: #5a5ac9;
}

/******************************** 
    popular Slider ENd
 ********************************/
/******************************** 
# featured section
********************************/
.featured-init .product-thumbnail img {
  max-width: 120px;
}

.featured-init .product-desc {
  padding: 0 15px;
}

.featured-init2 .product-thumbnail img {
  max-width: 120px;
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .featured-init2 .product-thumbnail img {
    max-width: 100px;
  }
}

.featured-init .product-thumbnail {
  width: auto;
}

/******************************** 
# featured section End
********************************/
/******************************** 
    product-ctry-init
 ********************************/
.product-ctry-init .product-desc .title {
  font-size: 18px;
  line-height: 20px;
}

@media screen and (max-width: 576px) {
  .product-ctry-init .product-desc .title {
    font-size: 15px;
  }
}

.product-ctry-init .product-price .del {
  font-size: 19px;
}

.product-ctry-init .product-price .onsale {
  font-size: 24px;
}

.availability p {
  font-size: 14px;
  text-transform: capitalize;
  color: #777;
}

.availability span.in-stock {
  font-weight: 400;
  color: #10a341;
}

.hot-deal .title {
  font-weight: 500;
  font-size: 30px;
}

/* clockdiv css */
.clockdiv {
  line-height: 1;
  margin-top: 20px;
}

.clockdiv .title {
  font-size: 14px;
  color: #707070;
  line-height: 18px;
  max-width: 100px;
  margin-right: 15px;
}

@media screen and (max-width: 767px) {
  .clockdiv .title {
    max-width: 100%;
    margin: 0 auto 15px;
    text-align: center;
  }
}

.clockdiv span {
  display: inline-block;
  padding: 0 10px;
  text-align: center;
  color: #111111;
  position: relative;
  font-weight: 500;
  font-size: 22px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .clockdiv span {
    font-size: 18px;
  }
}

.clockdiv span::after {
  position: absolute;
  top: 0;
  left: -4px;
  content: ":";
}

.clockdiv span:first-child::after {
  display: none;
}

.clockdiv span p {
  font-size: 12px;
  margin: 5px 0 0;
  color: #111111;
  font-weight: 400;
}

.product-sync-init .product-thumb img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

/******************************** 
    product-ctry-init ENd
 ********************************/
/******************************** 
    countdown-sync-nav Slider
 ********************************/
.countdown-sync-nav {
  max-width: 80%;
  margin: auto;
}

.theme1 .product-sync-nav {
  margin: 0 auto;
}

.theme1 .product-sync-nav .slick-slide {
  padding: 0 5px;
}

.theme1 .product-sync-nav .slick-slide .product-thumb {
  border: 1px solid transparent;
}

.theme1 .product-sync-nav .slick-slide .product-thumb:hover {
  border-color: #5a5ac9;
}

.theme1 .product-sync-nav .slick-slide .product-thumb img {
  width: 100%;
}

.theme1 .product-sync-nav .slick-slide.slick-current .product-thumb {
  border-color: #5a5ac9;
}

.has-opacity .slick-slide img {
  opacity: 0.3;
}

.has-opacity .slick-slide.slick-current.slick-active img {
  opacity: 1;
}

/******************************** 
    countdown-sync-nav Slider End
 ********************************/
/*******************************
 blog-init
 *******************************/
.blog-init .slick-list {
  margin: 0 -15px;
}

.blog-init .slick-list .slick-slide {
  padding: 0 15px;
}

.blog-thumb img {
  width: 100%;
}

.blog-post-content .title {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.blog-post-content .sub-title {
  text-transform: capitalize;
  font-size: 14px;
}

.blog-post-content .sub-title a:hover {
  text-decoration: underline;
}

.blog-post-content .text {
  margin-bottom: 15px;
}

.blog-post-content .read-more {
  font-size: 14px;
  text-transform: capitalize;
  display: inline-block;
  color: #5a5ac9;
}

.blog-post-content .read-more:hover {
  text-decoration: underline;
}

.blog-post-content .separator {
  margin: 0 5px;
}

.blog-link {
  font-weight: 600;
  font-size: 12px;
  color: #5a5ac9;
}

.blog-link:hover {
  text-decoration: underline;
}

.blog-links li {
  margin-bottom: 5px;
}

.blog-links li a {
  color: #666;
}

.blog-links li:hover a {
  color: #5a5ac9;
}

/*******************************
 blog-init End
 *******************************/
/******************************** 
     brand section 
  ********************************/
.brand-init.border-top {
  border-color: #ebebeb !important;
}

.brand-init .slick-list {
  margin: 0 -15px;
}

.brand-init .slick-list .slick-slide {
  padding: 0 15px;
}

.single-brand a img {
  display: block;
  margin: 0 auto;
}

.single-brand a:hover img {
  transform: scale(1.045) rotate(0.05deg);
}

/******************************** 
     brand section End
  ********************************/
/******************************** 
   pagination
 ********************************/
.page-item {
  margin: 0 4px;
}

.page-item .page-link {
  display: block;
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-weight: 400;
  color: #111111 !important;
  background: #f6f6f6 !important;
  font-size: 18px;
  text-align: center;
  border: 0 !important;
  border-radius: 50% !important;
}

.page-item .page-link i {
  font-size: 13px;
  position: relative;
  top: -2px;
}

.page-item.active .page-link,
.page-link:hover {
  color: #ffffff !important;
  background: #5a5ac9 !important;
}

/******************************** 
   pagination END
 ********************************/
/*************************************
 common modal CSS 
*************************************/
/* modal style1 */
.modal.style1 .modal-dialog {
  max-width: 1020px;
}

.modal.style1 .modal-header {
  border: none;
  padding: 6px 0.625rem;
  height: 30px;
}

.modal.style1 .modal-header .close {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 1;
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 0;
}

.modal.style1 .modal-header .close:hover {
  color: #111111 !important;
}

.modal.style1 .modal-body {
  padding: 30px 30px 90px;
}

.modal.style1 .product-head .title {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  margin: 0 0 20px 0;
}

.modal.style1 .product-head .sub-title {
  font-size: 14px;
  color: #565656;
  margin: 0 0 10px 0;
}

.modal.style1 .product-head .star-content .star-on {
  color: #fdd835;
  font-size: 12px;
}

.modal.style1 .product-head .star-content .de-selected {
  color: #e0e0e0;
}

.modal.style1 .new-price {
  text-transform: capitalize;
  font-weight: 500;
  line-height: 30px;
  color: #111111;
  font-size: 26px;
  margin-bottom: 30px;
}

@media (max-width: 576px) {
  .modal.style1 .product-head .title {
    margin: 0 0 2rem 0;
    font-size: 18px;
    line-height: 1.4;
  }
}

.check-box .title {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #111111;
  line-height: 24px;
}

.pro-social-links ul li {
  margin-right: 15px;
}

.pro-social-links ul li.share {
  line-height: 30px;
  font-weight: 500;
  color: #111111;
  font-size: 14px;
}

.pro-social-links ul li a {
  display: block;
  width: 20px;
  text-align: center;
  color: #9d9d9d;
  font-size: 18px;
  line-height: 30px;
}

.theme1 .pro-social-links ul li a:hover {
  color: #5a5ac9;
}

.addto-whish-list a {
  display: inline-block;
  line-height: 30px;
  padding: 0;
  border: none;
  color: #666;
  font-size: 14px;
  margin-right: 15px;
}

.theme1 .addto-whish-list a:hover {
  color: #5a5ac9;
}

.product-discount {
  margin: 15px 0px 0px;
}

.regular-price {
  font-weight: 500;
  line-height: 30px;
  color: #111111;
  font-size: 26px;
}

/* modal style2 */
.modal.style2 .modal-dialog {
  max-width: 300px;
}

.modal.style2 .modal-header {
  border: 0;
  padding: 0;
}

.modal.style2 .modal-header .close {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 1;
  position: absolute;
  top: 25px;
  right: 30px;
  padding: 0;
  z-index: 999;
}

.modal.style2 .modal-header .close:hover {
  color: #111111 !important;
}

.modal.style2 .title {
  padding: 45px 25px;
  text-align: center;
  font-size: 14px;
  color: #707070;
}

.modal.style2 .title i {
  margin-right: 15px;
}

/* modal style3 */
.modal.style3 .modal-dialog {
  max-width: 1140px;
}

.modal.style3 .modal-header {
  padding: 15px;
  border-bottom: 1px solid #f5f5f5;
}

.modal.style3 .modal-header .close {
  opacity: 1;
  position: absolute;
  top: 10px;
  right: 30px;
  padding: 0;
  z-index: 999;
  color: #ffffff;
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  text-shadow: 0 1px 0 #ffffff;
}

.modal.style3 .modal-header .close:hover {
  color: #111111;
  color: #111111;
}

.modal.style3 .modal-title {
  text-align: center;
  padding-right: 20px;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1;
  font-weight: 500;
}

.modal.style3 .modal-title span {
  font-size: 15px;
  margin-right: 15px;
}

.modal.style3 .modal-body {
  padding: 3.125rem 1.875rem;
}

.modal.style3 .product-name {
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
  color: #111111;
}

.modal.style3 .price {
  line-height: 1.4;
  color: #111111;
  font-size: 14px;
  font-weight: 500;
}

.modal.style3 .color {
  line-height: 2.5;
  color: #111111;
  font-size: 14px;
  font-weight: 500;
}

.modal.style3 .quantity {
  color: #111111;
  font-size: 14px;
  font-weight: 500;
}

.modal-cart-content {
  padding-left: 2.5rem;
}

.modal-cart-content strong {
  font-weight: 500;
  color: #111111;
  font-size: 14px;
}

.modal-cart-content p {
  color: #111111;
  font-size: 14px;
}

@media (max-width: 767px) {
  .modal-cart-content {
    padding-left: 0px;
  }
}

.divide-right {
  border-right: 1px solid #ebebeb;
}

.dmc {
  font-size: 14px !important;
  font-weight: 400;
}

@media (max-width: 767px) {
  .divide-right {
    border-right: 1px solid transparent;
  }
}

@media (max-width: 991px) {
  .modal.style3 img {
    display: block;
    max-width: 180px;
    margin: 0 auto 1.5rem;
  }
}

.cart-content-btn i {
  font-size: 15px;
  margin-right: 15px;
  vertical-align: middle;
}

/* modal 4 style */
.style4 .modal-dialog {
  max-width: 600px;
}

.style4 .modal-title {
  font-weight: 400;
  padding: 10px;
  font-size: 13px;
  text-transform: uppercase;
  background: #111111 !important;
}

.style4 .modal-header {
  padding: 30px 30px 0px;
  margin-bottom: 10px;
  border: 0px;
}

.style4 .modal-body {
  padding: 15px 30px 30px;
}

.style4 .modal-form > .title {
  font-weight: 500;
  color: #111111;
  line-height: 24px;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.style4 .modal-form label {
  margin: 12px 0 4px 0;
  text-align: right;
  display: block;
  font-weight: 500;
  color: #111111;
  font-size: 14px;
}

.style4 .modal-form label.title {
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  font-weight: 500;
  color: #111111;
  font-size: 14px;
}

.style4 .modal-form .form-control {
  padding: 0 5px;
  min-height: 28px;
  height: 28px;
  border: 1px solid #ebebeb;
  border-radius: 0;
  box-shadow: none;
}

.style4 .modal-form .form-control.textarea {
  height: 80px;
}

.style4 .modal-thumb img {
  border: 1px solid #ebebeb;
}

.style4 .modal-thumb .title {
  padding: 10px 0 5px;
  font-size: 13px;
  font-weight: 500;
  color: #111111;
  line-height: 18px;
}

.style4 .star-content span {
  color: #fdd835;
  font-size: 18px;
}

.style4 .star-content span.quality {
  font-weight: 500;
  color: #111111;
  font-size: 14px;
}

.style4 .required {
  font-size: 12px;
  color: #111111;
  margin-bottom: 10px;
}

.or {
  font-size: 12px;
  color: #111111;
}

/*************************************
 common modal CSS  ENd
*************************************/
/*------------------*
# Main Content ENd
*------------------*/
/************************************ 

[ 09.1 single-product CSS ]

*************************************/
/************************
   single-product start
 ************************/
.single-product-head .title {
  font-weight: 600;
  font-size: 30px;
}

.single-product-head .star-content .star-on {
  margin: 0 1px 0 0;
  color: #fdd835;
  font-weight: 400;
  font-size: 18px;
}

.single-product-head .star-content a {
  font-size: 15px;
  font-weight: 400;
}

.single-product-head .star-content a span {
  margin-right: 6px;
}

.product-tab-menu.single-product .nav-item {
  margin-bottom: 30px;
}

.product-tab-menu.single-product .nav-item .nav-link {
  text-transform: capitalize;
  color: #111111;
  padding: 0 0 15px 0;
  margin: 0 30px;
  font-size: 24px;
  font-weight: 600;
  border: 0px;
  border-radius: 0px;
}

@media screen and (max-width: 767px) {
  .product-tab-menu.single-product .nav-item .nav-link {
    font-size: 18px;
  }
}

.product-tab-menu.single-product .nav-item .nav-link:hover {
  color: #5a5ac9;
}

.product-tab-menu.single-product .nav-item .nav-link.active {
  color: #5a5ac9;
}

.single-product-desc {
  background: #ffffff;
  padding: 30px;
  border: 1px solid #ebebeb;
}

.single-product-desc ul {
  font-size: 15px;
  line-height: 24px;
  text-align: left;
}

.studio-thumb img {
  border: 1px solid #ebebeb;
}

.studio-thumb h3 {
  text-transform: capitalize;
  font-weight: 500;
  color: #111111;
  line-height: 24px;
  font-size: 0.9375rem;
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.studio-thumb h6 {
  font-size: 1rem;
  color: #111111;
  font-weight: 500;
}

.studio-thumb h6 small {
  font-weight: 400;
  font-size: 14px;
  color: #707070;
}

.product-features ul {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.product-features ul li {
  flex: 1 0 40%;
  font-weight: 400;
  background: #f1f1f1;
  padding: 0.625rem;
  margin-right: 0.625rem;
  min-height: 2.5rem;
  word-break: normal;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.grade-content .grade {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #777;
  margin-right: 10px;
}

.grade-content .star-on {
  color: #fdd835;
  font-weight: 400;
  font-size: 18px;
}

.grade-content .title {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #777;
  margin-top: 20px;
  margin-bottom: 10px;
}

.grade-content .sub-title {
  color: #111111;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  margin-bottom: 10px;
}

.grade-content p {
  line-height: 18px;
  font-size: 14px;
  color: #9d9d9d;
}

.group-img img {
  max-width: 80px;
}

.product-grouped .title {
  margin-bottom: 0.8rem;
  color: #111111;
  font-size: 15px;
  text-transform: capitalize;
}

.product-grouped span {
  font-size: 14px;
  color: #707070;
  line-height: 1;
}

.product-anotherinfo-wrapper ul li {
  color: #888;
  font-size: 14px;
  list-style: outside none none;
  margin: 0 0 13px;
}

.product-anotherinfo-wrapper ul li span {
  color: #111111;
  display: inline-block;
  font-weight: 500;
  margin: 0 26px 0 0;
  min-width: 85px;
}

.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.single-review .review-top-wrap .review-left .review-name {
  margin-right: 25px;
}

.single-review .review-top-wrap .review-left .review-name h4 {
  font-size: 16px;
  font-weight: 600;
}

.single-review .review-top-wrap .review-left .rating-product {
  line-height: 1;
}

.rating-product {
  margin-bottom: 6px;
}

.rating-product i {
  overflow: hidden;
  font-size: 16px;
  color: #fdd835;
}

.single-review .review-top-wrap .review-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.review-left a {
  color: #565656;
  margin-left: auto;
}

.single-review .review-bottom p {
  margin: 0;
  max-width: 93%;
}

.ratting-form-wrapper .ratting-form form .star-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 0 20px;
}

.ratting-form-wrapper h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.ratting-form-wrapper .ratting-form form .star-box span {
  margin: 0 15px 0 0;
}

.rating-form-style {
  margin-bottom: 10px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style input,
.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #333;
}

.rating-form-style input {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #253237;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

.rating-form-style {
  margin-bottom: 10px;
}

.ratting-form-wrapper .ratting-form form .rating-form-style textarea {
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
}

.rating-form-style input[type="submit"] {
  padding: 0 55px !important;
  line-height: 48px;
  height: 48px;
  width: auto;
  font-size: 15px;
  font-weight: 600;
  border: 0;
  box-shadow: none;
  text-transform: uppercase;
  background: #111111 !important;
  color: #fff !important;
  transition: all 300ms linear;
}

.rating-form-style input[type="submit"]:hover {
  background: #5a5ac9 !important;
}

/************************
   single-product End
 ************************/
/**************************** 
[ 21. footer CSS ]
*****************************/
/*------------------*
# Footer
*------------------*/
.nletter-form .form-control {
  height: 47px;
  background: #ffffff;
  color: #7e7e7e;
  padding: 10px 120px 10px 10px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #ffffff;
  border-radius: 30px;
}

.nletter-form .form-control:focus {
  box-shadow: none;
}

@media screen and (max-width: 767px) {
  .social-network {
    padding-bottom: 20px;
  }
}

.social-network li {
  margin: 0 10px 0 0;
}

.social-network li a {
  display: block;
  color: #111111;
  background: #ffffff;
  line-height: 44px;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  border-radius: 50%;
}

.theme1 .social-network li a:hover {
  color: #ffffff;
  background: #5a5ac9;
}

.social-network2 li {
  margin: 0 15px 0 0;
}

@media screen and (max-width: 767px) {
  .social-network2 li {
    margin: 0 10px 0 0;
  }
}

.social-network2 li a {
  display: block;
  line-height: 1;
  text-align: center;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .social-network2 li a {
    font-size: 16px;
  }
}

.theme1 .social-network2 li a {
  color: #ffffff;
}

.theme1 .social-network2 li a:hover {
  color: #5a5ac9;
}

.theme1 .social-network2.modify li a:hover {
  color: #ffffff;
}

.address {
  margin: 0;
}

.address-widget .title {
  font-size: 22px;
  font-weight: 500;
}

.address-widget .text {
  line-height: 1;
  color: #707070;
  font-size: 13px;
}

.theme1 .address-widget .title a:hover {
  color: #5a5ac9;
}

/* footer-menu */
.mx-w-400 {
  max-width: 400px;
}

.help-text {
  font-size: 16px;
  margin-bottom: 5px;
}

.footer-widget .section-title {
  position: relative;
  padding-bottom: 20px;
}

.footer-widget .section-title::after {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  content: "";
}

.footer-widget .section-title .title {
  font-weight: 600;
  font-size: 18px;
}

.footer-widget .section-title .title::after {
  display: none;
}

.theme1 .footer-widget .section-title::after {
  background: #5a5ac9;
}

.footer-menu li {
  margin-bottom: 5px;
}

.footer-menu li:last-child {
  margin-bottom: 0;
}

.footer-menu li a {
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #565656;
}

.footer-menu li a:hover {
  padding-left: 5px;
}

.theme1 .footer-menu li a:hover {
  color: #5a5ac9;
}

/* coppy-right */
.coppy-right p {
  color: #ffffff;
}

.coppy-right a {
  color: #5a5ac9;
}

.theme1 .coppy-right a:hover {
  color: #5a5ac9;
  text-decoration: underline;
}

/******************************** 
    footer section End
  ********************************/
/**************************** 
[ 22. scroll-up CSS ]
*****************************/
/******************************** 
    scrollUp style
 ********************************/
#scrollUp {
  background: #5a5ac9;
  width: 40px;
  height: 40px;
  bottom: 50px;
  right: 50px;
  border-radius: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 22px;
  line-height: 40px;
  font-weight: 500;
}

#scrollUp:hover {
  background: #8a72e0;
  color: #ffffff;
}

/******************************** 
    scrollUp style End
 ********************************/
/**************************** 
[ 23. pages ]
*****************************/
/**************************** 
[ 23.1 blogPages ]
*****************************/
/*------------------*
# Blog Pages
*------------------*/
/* 10. Blog Section CSS */
.blog-title {
  margin: 25px 0px 0px;
}

.blog-title .title {
  color: #111111;
  font-size: 34px;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.blog-title .title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #5a5ac9;
}

/* blog left sidebar */
.sidebar-widget .post-title {
  font-size: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  display: block;
  border-bottom: 1px solid #ebebeb;
  text-transform: capitalize;
}

.sidebar-widget .post-title::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 60px;
  height: 2px;
  background: #5a5ac9;
}

.blog-search-form .form-control {
  height: 46px;
  border: 2px solid #5a5ac9;
  padding-right: 22%;
  font-size: 14px;
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .blog-search-form .form-control {
    padding-right: 25%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-search-form .form-control {
    padding-right: 30%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .blog-search-form .form-control {
    padding-right: 10%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .blog-search-form .form-control {
    padding-right: 15%;
  }
}

.blog-media-list .sub-title {
  font-size: 14px;
  font-weight: 700;
}

.post-thumb img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
}

.object-fit-none {
  object-fit: none;
}

.font-style-normal {
  font-style: normal !important;
}

.section-title.underline-shape h2 {
  font-weight: 700;
  font-size: 30px;
}

.comment-section {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #ebebeb;
}

.comment-section .title {
  text-transform: capitalize;
  color: #111111;
  line-height: 24px;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.comment-section .sub-title {
  text-transform: capitalize;
  color: #111111;
  line-height: 24px;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.comment-section span {
  display: block;
  text-transform: capitalize;
}

.comment-section p {
  margin: 30px 0;
  font-size: 14px;
}

.reply {
  color: #111111;
}

.reply:hover {
  color: #5a5ac9;
}

.avatar {
  margin: 20px 0;
}

.section-title.underline-shape {
  position: relative;
}

.blog-post-media {
  overflow: hidden;
  margin-bottom: 20px;
}

.blog-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.single-blog.blog-image img {
  height: auto;
}

.blog-post-media iframe {
  width: 100%;
  position: relative;
  height: 300px;
}

.blog-title {
  font-size: 30px;
  font-weight: 600;
}

.blog-title a:hover {
  color: #5a5ac9;
}

.blog-page-meta li a:hover {
  color: #5a5ac9;
}

.blog-page-meta li {
  display: inline-block;
}

.blog-page-meta li a {
  display: block;
  color: #565656;
}

.blog-page-meta li:first-child:after {
  position: absolute;
  top: 50%;
  right: -9px;
  left: auto;
  background: #555;
  content: "";
  width: 1px;
  height: 12px;
  margin-top: -6px;
}

.blog-page-meta li {
  display: inline-block;
  position: relative;
}

.blog-page-meta li:first-child {
  margin-right: 15px;
}

.blog-page-meta {
  margin: 10px 0;
}

.blog-post-content-inner p {
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px solid #ebebeb;
}

.blog-post-content-inner .read-more-btn:hover {
  color: #5a5ac9;
  padding-left: 5px;
}

.blog-gallery .slick-arrow i {
  font-size: 26px;
  color: #333;
}

.blog-gallery .slick-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
}

.blog-gallery .slick-arrow.next {
  left: auto;
  right: 0;
}

.blog-gallery:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
  left: 20px;
}

.blog-gallery:hover .slick-arrow.next {
  left: auto;
  right: 20px;
}

.blog-gallery .slick-slide img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.single-post-content p {
  margin: 0 0 25px;
}

.single-post-content p.quate-speech {
  margin-left: 15px;
  padding: 10px 15px;
  line-height: 26px;
  font-style: italic;
  border-left: 3px solid #5a5ac9;
}

@media screen and (min-width: 992px) {
  .single-post-content p.quate-speech {
    margin-left: 60px;
  }
}

.tag-list li {
  display: inline-block;
  margin-left: 5px;
  color: #111111;
}

.tag-list li a {
  color: #565656;
}

.tag-list li a:hover {
  color: #5a5ac9;
}

.social li {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  color: #111111;
}

.social li a {
  color: #565656;
}

.social li a:hover {
  color: #5a5ac9;
}

span.title {
  font-weight: 700;
  color: #111111;
}

.blog-related-post .blog-title {
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .blog-related-post .blog-title {
    font-size: 18px;
  }
}

.blog-related-post {
  padding: 40px 0 30px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

@media screen and (max-width: 767px) {
  .blog-related-post {
    padding: 40px 0 0px 0;
  }
}

.blog-single-tags-share {
  margin-bottom: 35px;
}

.comment-area .review-content {
  padding: 30px;
  border: 1px solid #ebebeb;
}

.comment-area {
  margin-top: 35px;
}

.comment-heading {
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0 0 40px;
}

.blog-comment-form .comment-heading {
  margin: 0 0 10px;
}

.blog-comment-form p {
  margin: 0 0 30px;
}

.review-name h4 {
  font-size: 18px;
  margin-bottom: 15px;
}

.single-form input,
.single-form textarea {
  padding: 2px 10px 2px 20px;
  background: transparent;
  border: 1px solid #e6e6e6;
  color: #333;
}

.single-form input {
  height: 45px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #111111;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
}

.single-form textarea {
  height: 180px;
  padding: 20px 10px 2px 20px;
  margin-bottom: 20px;
  width: 100%;
  outline: none;
}

.single-form input[type="submit"] {
  padding: 0 55px !important;
  line-height: 48px;
  width: auto;
  height: 48px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
  background: #5a5ac9 !important;
  color: #fff !important;
  transition: all 300ms linear;
  margin-top: 15px;
  margin-bottom: 0;
}

.single-form input[type="submit"]:hover {
  background: #111111 !important;
  color: #fff !important;
}

.single-blog .blog-post-content-inner {
  padding: 30px 0 0;
}

.comment-area .single-review.child-review {
  margin-bottom: 70px;
}

.single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

.single-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 50px;
}

@media screen and (max-width: 767px) {
  .single-review {
    flex-direction: column;
  }
}

.single-review .review-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  margin: 0 15px 0 0;
}

@media screen and (max-width: 767px) {
  .single-review .review-img {
    margin-bottom: 30px;
  }
}

.single-review img {
  max-width: 120px;
}

.single-form label {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .grid-view-list .product-thumbnail,
  .grid-view .product-thumbnail {
    width: 100%;
  }
}

.grid-view-list .product-thumbnail a,
.grid-view .product-thumbnail a {
  display: block;
}

@media screen and (max-width: 767px) {
  .grid-view-list .product-thumbnail a .second-img,
  .grid-view .product-thumbnail a .second-img {
    right: 0;
  }
}

.grid-view-list .product-thumbnail img,
.grid-view .product-thumbnail img {
  display: block;
  margin: 0 auto 20px;
  width: 100%;
}

@media screen and (min-width: 767px) {
  .grid-view-list .product-thumbnail img {
    margin-bottom: 0;
  }
}

/******************************** 
    Blog Section End
 ********************************/
/**************************** 
[ 23.2 shopPages ]
*****************************/
/*------------------*
# Shop Pages
*------------------*/
.tab-content .tab-pane.active .grid-view .product-card {
  animation: zoomIn 0.5s ease;
  animation-duration: 1s;
}

.tab-content .tab-pane .grid-view-list .product-card {
  padding: 15px;
}

.tab-content .tab-pane.active .grid-view-list .product-card {
  animation: fadeInRight 0.5s ease;
  animation-duration: 1s;
}

.grid-nav-wraper {
  padding: 10px 30px;
}

@media screen and (max-width: 991px) {
  .grid-nav-wraper {
    padding: 10px 15px;
  }
}

.shop-grid-nav .nav .nav-item {
  margin-right: 15px;
  line-height: 1;
}

.total-products {
  font-weight: 400;
  margin-left: 50px;
  line-height: 20px;
  font-size: 14px;
  padding: 5px;
}

@media screen and (max-width: 991px) {
  .total-products {
    margin-left: 20px;
  }
}

.shop-grid-nav .nav .nav-link {
  color: #111111;
  padding: 0;
  background: transparent;
  font-size: 18px;
  line-height: 30px;
}

.shop-grid-nav .nav .nav-link.active,
.shop-grid-nav .nav .nav-link:hover {
  color: #5a5ac9;
}

.shop-grid-button button {
  width: 100%;
  background: #ffffff;
  padding: 2px 15px;
  line-height: 24px;
  font-size: 14px;
  color: #111111;
  text-align: left;
  box-shadow: none;
}

.shop-grid-button button span {
  font-size: 20px;
}

.shop-grid-menu {
  background: #ffffff;
  width: calc(100% - 145px);
  border: 1px solid #ebebeb;
}

.shop-grid-menu .dropdown-item {
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  padding: 3px 15px;
  color: #111111;
}

.shop-grid-menu .dropdown-item:hover {
  background: #5a5ac9;
  color: #ffffff;
}

.sort-by {
  min-width: 70px;
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
  padding: 5px;
  color: #111111;
  margin-left: 50px;
}

@media screen and (max-width: 991px) {
  .sort-by {
    margin-left: 0px;
  }
}

.product-list-des {
  padding: 15px 0;
}

.availability-list p span {
  color: #5a5ac9;
}

.grid-view-list .product-desc .title {
  font-size: 20px;
}

.custom-select {
  padding: 0px 15px;
  height: 30px !important;
  border: 0px;
  font-size: 14px;
}

/*------------------*
# Shop Pages End
*------------------*/
/**************************** 
[ 23.3 about-us ]
*****************************/
/*------------------*
# About Us Page
*------------------*/
.about-content .title {
  font-weight: 600;
  color: #111111;
  text-transform: capitalize;
  font-size: 30px;
  max-width: 900px;
  margin: 0 auto 20px;
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  .about-content .title {
    font-size: 25px;
  }
}

.about-info .title {
  font-size: 34px;
  font-weight: 600;
  color: #111111;
}

.single-service .title {
  font-size: 22px;
  font-weight: 600;
  color: #111111;
}

.service-section .single-blog img {
  width: 100%;
}

/* *******************
   about us page End
 **********************/
/**************************** 
[ 23.4 whishlist ]
*****************************/
/*------------------*
# Whishlist Page
*------------------*/
@media (max-width: 991px) {
  .whish-list-section .table {
    min-width: 1030px;
  }
}

.whish-list-section .title {
  font-weight: 700;
  font-size: 30px;
  border-bottom: 1px solid #ebebeb;
}

.whish-list-section .table .thead-light th {
  background-color: #5a5ac9;
  border-color: transparent;
  text-transform: capitalize;
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
}

.whish-list-section .table td,
.whish-list-section .table th {
  vertical-align: middle;
}

.whish-list-section img {
  max-width: 150px;
}

.whish-title {
  font-size: 15px;
}

.whish-list-price {
  color: #111111;
  font-size: 15px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f1f1f1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=) no-repeat scroll right 0.5rem center/1.25rem 1.25rem;
}

/* ***************
  whishlist End
 ******************/
/**************************** 
[ 23.5 contact-us ]
*****************************/
/*------------------*
# Contact Us Page
*------------------*/
/* ***************
 contact-form
******************/
.form-control:focus {
  box-shadow: none;
}

.contact-form .title {
  text-transform: capitalize;
  color: #111111;
  line-height: 24px;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.col-form-label {
  font-size: 14px;
  color: #111111;
  padding-top: 0.625rem;
  text-transform: capitalize;
}

.contact-form .form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 16px;
  color: #707070;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  border-radius: 0px;
}

.contact-form textarea.form-control {
  min-height: 300px;
}

@media screen and (max-width: 767px) {
  .contact-form textarea.form-control {
    min-height: 150px;
  }
}

.contact-page-title {
  font-size: 30px;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .contact-page-title {
    font-size: 25px;
  }
}

.single-contact-block {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 575px) {
  .single-contact-block {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

.single-contact-block:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.single-contact-block h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #111111;
  margin: 15px 0px;
}

@media only screen and (max-width: 575px) {
  .single-contact-block h4 {
    font-size: 20px;
  }
}

.single-contact-block h4 img {
  margin: 5px 20px 20px 0;
}

.single-contact-block p {
  font-size: 15px;
  line-height: 29px;
  margin-bottom: 0;
  color: #707070;
}

span.required {
  color: #5a5ac9;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form label {
  font-size: 14px;
  color: #111111;
  padding: 0.8rem 0rem;
  text-transform: capitalize;
}

.contact-form input {
  line-height: 40px;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  border: 1px solid #ebebeb;
  outline: 0;
  background-color: #ffffff;
}

.contact-form textarea {
  line-height: 40px;
  width: 100%;
  height: 155px;
  padding-left: 20px;
  border: 1px solid #ebebeb;
  outline: 0;
  background-color: #ffffff;
}

.contact-page-side-content {
  padding-right: 30px;
  height: 100%;
}

/* login page */
.log-in-form {
  border: 1px solid #ebebeb;
  font-size: 13px;
  color: #707070;
  padding: 30px 15px 15px;
}

.log-in-form .form-control {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 14px;
  color: #707070;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  border-radius: 0px;
}

.show-password {
  font-weight: 500;
  color: #ffffff;
  border: 0;
  text-transform: uppercase;
  font-size: 0.6875rem;
  padding: 0.67rem 1rem !important;
  border-radius: 0px;
}

.login-form-links a {
  display: inline-block;
}

.for-get {
  font-size: 14px;
  padding-bottom: 15px;
}

.border-top {
  border-top: 1px solid #ebebeb !important;
}

.no-account {
  display: inline-block;
  font-size: 14px;
  margin-top: 15px;
}

.form-message.error {
  color: #ff0000;
}

.form-message.success {
  color: #008000;
}

/* ***************
 contact-form End
******************/
/*------------------*
# Contact Us End
*------------------*/
/**************************** 
[ 23.6 account ]
*****************************/
/*------------------*
# Account Page
*------------------*/
.my-account .title {
  border-bottom: 1px solid #ebebeb;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 30px;
}

.register .title {
  border-bottom: 1px solid #ebebeb;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-size: 30px;
}

.myaccount-tab-menu {
  flex-direction: column;
  background-color: #ffffff;
}

.myaccount-tab-menu a {
  border: 1px solid #ebebeb;
  border-bottom: 0;
  color: #111111;
  font-weight: 500;
  display: block;
  padding: 15px 15px 13px;
  line-height: 30px;
  font-size: 15px;
  text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ebebeb;
}

.myaccount-tab-menu a:hover,
.myaccount-tab-menu a.active {
  background-color: #5a5ac9;
  color: #ffffff;
}

.myaccount-tab-menu a i {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

.myaccount-content {
  background-color: #ffffff;
  font-size: 14px;
  border: 1px solid #ebebeb;
  padding: 30px;
}

@media only screen and (max-width: 575px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}

.myaccount-content h3 {
  border-bottom: 1px solid #ebebeb;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.myaccount-content .welcome a {
  color: #111111;
}

.myaccount-content .welcome a:hover {
  color: #5a5ac9;
}

.myaccount-content .welcome strong {
  font-weight: 600;
}

.myaccount-content a.edit-address-btn {
  border-color: #333;
}

.myaccount-content a.edit-address-btn i {
  padding-right: 5px;
}

.myaccount-content a.edit-address-btn:hover {
  color: #5a5ac9;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 15px;
}

.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
}

.myaccount-table table td,
.myaccount-table .table td {
  padding: 20px 10px;
  vertical-align: middle;
}

.myaccount-table table td a:hover,
.myaccount-table .table td a:hover {
  color: #ffffff;
}

.saved-message {
  font-weight: 600;
  font-size: 13px;
  padding: 20px;
}

.account-details-form h4 {
  text-transform: capitalize;
  margin: 0;
  color: #111111;
  font-weight: 500;
  font-size: 18px;
}

.table .thead-light th {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.account-details-form input[type="text"],
.account-details-form input[type="email"],
.account-details-form input[type="url"],
.account-details-form input[type="password"],
.account-details-form input[type="search"],
.account-details-form input[type="number"],
.account-details-form input[type="tel"] {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  font-size: 14px;
  color: #707070;
  padding: 0.8rem 1.6rem;
  height: 38px;
  line-height: 1.25;
  border-radius: 0px;
}

/* ***************
  my-account End
 ******************/
/**************************** 
[ 23.7 check-out ]

*****************************/
/*------------------*
# Check out Page
*------------------*/
.billing-info-wrap .billing-info input,
.billing-info-wrap .billing-select input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #666;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  outline: none;
  height: 45px;
}

.billing-info-wrap .checkout-account-toggle input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #666;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0 0 20px;
  width: 100%;
  height: 45px;
  outline: none;
}

.billing-info-wrap .additional-info-wrap .additional-info textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #333;
  font-size: 14px;
  height: 138px;
  padding: 17px 20px;
  width: 100%;
  outline: none;
}

.billing-info-wrap .title {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
}

.your-order-area .title {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 30px;
}

.billing-info {
  margin-bottom: 20px;
}

.billing-info label {
  font-size: 14px;
  margin-bottom: 10px;
}

.form-select {
  height: 45px;
  border-radius: 0px;
  outline: 0;
  color: #565656;
}

.form-select:focus {
  border-color: #e6e6e6;
  outline: 0;
  box-shadow: none;
}

.open-toggle,
.open-toggle2 {
  display: none;
}

.your-order-area .your-order-wrap {
  padding: 38px 45px 44px;
  background: #f6f6f6;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul li {
  font-size: 24px;
  text-transform: capitalize;
  color: #111;
  font-weight: 500;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul li {
  font-size: 15px;
  font-weight: 500;
  list-style: outside none none;
  color: #111111;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-middle {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 29px 0;
  padding: 19px 0 18px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul li.your-order-shipping {
  font-size: 15px;
  color: #111111;
  font-weight: 500;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-middle ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 10px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.billing-info-wrap .billing-info input,
.billing-info-wrap .billing-select input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #666;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  width: 100%;
  outline: none;
  height: 45px;
}

.billing-info-wrap .nice-select {
  width: 100%;
  margin-bottom: 20px;
}

.billing-info-wrap .nice-select .list {
  width: 100%;
}

.billing-info-wrap .nice-select:active,
.billing-info-wrap .nice-select.open,
.billing-info-wrap .nice-select:focus {
  border-color: #ebebeb;
}

.billing-info-wrap .billing-info input.billing-address,
.billing-info-wrap .billing-select input.billing-address {
  margin-bottom: 10px;
}

.billing-info-wrap .checkout-account label {
  color: #666;
  font-weight: 400;
  margin: 0 0 0 12px;
}

.billing-info-wrap .checkout-account input {
  border: 1px solid #e6e6e6;
  display: inline-block;
  float: left;
  height: 12px;
  width: 12px;
  margin-top: 7px;
  cursor: pointer;
}

.billing-info-wrap .checkout-account-toggle input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #666;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 10px;
  margin: 0 0 20px;
  width: 100%;
  height: 45px;
  outline: none;
}

.billing-info-wrap .additional-info-wrap h4 {
  font-size: 16px;
  font-weight: 700;
  color: #111111;
  margin: 0 0 10px;
}

.billing-info-wrap .additional-info-wrap .additional-info textarea {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  color: #333;
  font-size: 14px;
  height: 138px;
  padding: 17px 20px;
  width: 100%;
  outline: none;
}

.additional-info-wrap {
  margin: 30px 0;
}

.your-order-area .your-order-wrap {
  padding: 38px 45px 44px;
  background: #f6f6f6;
}

@media screen and (max-width: 767px) {
  .your-order-area .your-order-wrap {
    padding: 15px;
  }
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-top ul li {
  font-size: 15px;
  font-weight: 500;
  list-style: outside none none;
  color: #111111;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-middle {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 29px 0;
  padding: 19px 0 18px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-middle ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 10px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-bottom ul li.your-order-shipping {
  font-size: 15px;
  color: #111111;
  font-weight: 500;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total {
  border-bottom: 1px solid #dee0e4;
  border-top: 1px solid #dee0e4;
  margin: 18px 0 33px;
  padding: 17px 0 19px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total ul {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total ul li.order-total {
  font-weight: 500;
  color: #111111;
  font-size: 15px;
}

.your-order-area .your-order-wrap .your-order-product-info .your-order-total ul li {
  font-weight: 600;
  color: #5a5ac9;
  font-size: 16px;
  list-style: outside none none;
}

.your-order-area .payment-accordion:last-child {
  margin: 0 0 0;
}

.your-order-area .payment-accordion {
  margin: 0 0 16px;
}

.your-order-area .payment-accordion {
  margin: 0 0 16px;
}

.your-order-area .payment-accordion h4 a {
  color: #111111;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.your-order-area .payment-accordion .panel-body {
  padding: 5px 0 0 0;
}

.your-order-area .payment-accordion .panel-body p {
  padding: 0 0 0 10px;
}

.Place-order {
  margin-top: 25px;
}

.open-toggle,
.open-toggle2 {
  display: none;
}

.single-my-account h3.panel-title {
  background-color: #f9f9f9;
  border-bottom: 1px solid #ebebeb;
  color: #111111;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  position: relative;
  text-transform: uppercase;
  line-height: 2;
}

.single-my-account h3.panel-title span {
  color: #111111;
  font-size: 14px;
  left: 20px;
  position: absolute;
  top: 16px;
}

.single-my-account h3.panel-title a {
  color: #111111;
  display: block;
  padding: 16px 55px;
  position: relative;
}

.single-my-account h3.panel-title a:hover {
  color: #5a5ac9;
}

.single-my-account h3.panel-title a:hover::before {
  color: #5a5ac9;
}

.single-my-account h3.panel-title a::before {
  color: #000;
  content: "\f3d0";
  display: inline-block;
  font-family: "Ionicons";
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -14px;
}

.single-my-account .myaccount-info-wrapper {
  padding: 30px 20px;
  background-color: #fff;
}

.single-my-account .myaccount-info-wrapper .account-info-wrapper {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 28px;
  padding-bottom: 30px;
}

.single-my-account .myaccount-info-wrapper .account-info-wrapper h4 {
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
  color: #111111;
  font-weight: 700;
}

.single-my-account .myaccount-info-wrapper .account-info-wrapper h5 {
  font-size: 14px;
  letter-spacing: 0.2px;
  margin-top: 7px;
  font-weight: 600;
  color: #111111;
}

.single-my-account .myaccount-info-wrapper .billing-info {
  margin-bottom: 20px;
}

.single-my-account .myaccount-info-wrapper .billing-info label {
  color: #666;
  font-size: 14px;
  text-transform: capitalize;
}

.single-my-account .myaccount-info-wrapper .billing-info input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  color: #666;
  height: 40px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 26px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a {
  color: #111111;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a:hover {
  color: #5a5ac9;
}

.single-my-account .myaccount-info-wrapper .billing-back-btn .billing-back a i {
  font-size: 15px;
  color: #5a5ac9;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info {
  padding: 30px 20px;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-info p {
  color: #666;
  font-size: 14px;
  margin: 0;
  text-transform: capitalize;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a.edit {
  background-color: #0090f0;
  font-weight: 700;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a.edit:hover {
  background-color: #5a5ac9;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a:hover {
  background-color: #5a5ac9;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper .entries-edit-delete a {
  background-color: #f32013;
  color: #fff;
  display: inline-block;
  line-height: 1;
  margin: 0 2px;
  padding: 12px 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.single-my-account {
  margin-bottom: 20px;
  border: 1px solid #ebebeb;
}

.single-my-account:last-child {
  margin: 0;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper {
  border: 1px solid #eaeaea;
  position: relative;
}

.single-my-account .myaccount-info-wrapper .entries-wrapper::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: 50%;
  top: 0;
  background-color: #eaeaea;
}

.login-register-wrapper .login-register-tab-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 40px;
}

.login-register-wrapper .login-register-tab-list a {
  position: relative;
}

.login-register-wrapper .login-register-tab-list a:last-child:before {
  display: none;
}

.login-register-wrapper .login-register-tab-list a::before {
  background-color: #000;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 1px;
}

.login-register-wrapper .login-register-tab-list a.active h4 {
  color: #5a5ac9;
}

.login-register-wrapper .login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 20px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: #111111;
}

.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .login-register-wrapper .login-form-container {
    padding: 30px;
  }
}

.login-register-wrapper .login-form-container .login-register-form form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  color: #666;
  font-size: 14px;
  height: 45px;
  margin-bottom: 30px;
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn {
  padding: 10px 0 19px;
}

.login-register-wrapper .login-form-container .login-register-form form input[type="checkbox"] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 1px;
  width: 17px;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
  color: #111111;
  font-size: 15px;
  font-weight: 400;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a.flote-none {
  float: none !important;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
  color: #111111;
  float: right;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media screen and (max-width: 480px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
    float: none;
  }
}

.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
  color: #5a5ac9;
}

.contact-map #mapid {
  height: 560px;
}

.custom-row-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.custom-row-2 div[class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.contact-info-wrap {
  background-color: #f3f3f3;
  padding: 120px 70px 116px 90px;
}

.contact-info-wrap .single-contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

.contact-info-wrap .single-contact-info .contact-icon {
  margin-right: 20px;
}

.contact-info-wrap .single-contact-info .contact-icon i {
  font-size: 20px;
  color: #111111;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #252525;
  text-align: center;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.contact-info-wrap .single-contact-info .contact-info-dec p {
  line-height: 1;
  color: #111111;
  margin: 0 0 9px;
}

.contact-info-wrap .single-contact-info .contact-info-dec p a {
  color: #111111;
}

.contact-info-wrap .single-contact-info .contact-info-dec p a:hover {
  color: #5a5ac9;
}

.contact-info-wrap .single-contact-info:hover .contact-icon i {
  background-color: #5a5ac9;
  color: #fff;
  border-color: #5a5ac9;
}

.contact-social h3 {
  font-weight: 700;
  color: #111111;
  font-size: 24px;
  margin: 0 0 17px;
  line-height: 1;
}

.contact-social ul li a {
  color: #666;
}

.contact-social .social-info li a {
  margin-right: 15px;
}

.contact-form {
  background-color: #f3f3f3;
  padding: 35px;
}

@media screen and (max-width: 767px) {
  .contact-form {
    padding: 15px;
  }
}

.contact-form .contact-title h2 {
  font-size: 24px;
  font-weight: 700;
  color: #111111;
  line-height: 1;
  margin-bottom: 36px;
  margin-left: -10px;
}

.contact-form .contact-form-style input,
.contact-form .contact-form-style textarea {
  background: transparent;
  border: 1px solid #c1c1c1;
  height: 40px;
  padding: 2px 14px;
  margin-bottom: 30px;
  color: #000;
  width: 100%;
  outline: none;
}

.contact-form p {
  color: #333;
}

.contact-form .contact-form-style textarea {
  padding: 20px 14px;
  margin-bottom: 0;
  height: 200px;
}

.contact-map {
  margin-bottom: 60px;
}

.contact-form .contact-form-style button {
  border: none;
  background-color: #5a5ac9;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  padding: 15px 52px;
  margin-top: 33px;
  outline: none;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 30px;
}

.contact-form .contact-form-style button:hover {
  background-color: #111111;
  color: #fff;
}

.coupon-title {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  margin: 20px 0px 15px;
}

.check-out-btn {
  padding: 14px 30px;
}

/**************************** 
[ 23.8 compare ]

*****************************/
/*------------------*
# Compare Page
*------------------*/
@media (max-width: 991px) {
  .compare-section .table {
    min-width: 800px;
  }
}

.compare-section img {
  max-width: 150px;
}

.compare-section .title {
  font-weight: 700;
  font-size: 30px;
  border-bottom: 1px solid #ebebeb;
}

.compare-section .sub-title {
  font-size: 15px;
  padding: 20px 0;
}

.compare-section .table .thead-light th {
  background-color: #ffffff;
  border-color: #ebebeb;
}

.compare-section .table td,
.compare-section .table th {
  vertical-align: middle;
  border: 1px solid #ebebeb;
  padding: 15px;
  font-size: 15px;
}

.compare-section .table th {
  text-transform: capitalize;
}

.compare-section .table td {
  text-transform: capitalize;
}

.compare-section .table td p {
  text-transform: initial;
}

/* ***************
  compare End
 ******************/
/**************************** 
[ 23.9 map ]

*****************************/
/*******************
   map page start
 ******************/
.map iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

/* ***************
   map page End
 ******************/
