/*******************************
 section-title style 
 *******************************/

.section-title {
  padding-bottom: 50px;
  .title {
    position: relative;
    font-size: 30px;
    font-weight: fontweight(bold);
    text-transform: capitalize;
    @include max-screen(480) {
      font-size: 20px;
    }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0px;
      width: 60px;
      height: 2px;
      content: "";
      background: $theme-color;
    }
  }
}

/*******************************
 section-title style  End
 *******************************/
